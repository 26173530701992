import { StyledEngineProvider, ThemeProvider, useMediaQuery, useTheme, CssBaseline } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, RouteComponentProps, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Admin } from './Components/Admin';
import { AuthConsumer, AuthProvider, LogoutListener } from './Components/Auth';
import { MyInventory, MyPipeline } from './Components/Dashboards';
import {
    ActiveLeads,
    ActiveTransactions,
    AllLeads,
    FundedTransactions,
    StalledLeads,
    StalledTransactions,
} from './Components/DealPipeline';
import GoogleAnalytics from './Components/External/GoogleAnalytics';
import FaviconManager from './Components/FaviconManager/FavconManager';
import FinanceApp from './Components/FinanceApp';
import ForgotPassword from './Components/ForgotPassword';
import { ActiveInventory, AllInventory, SoldInventory } from './Components/InventoryTracker';
import {
    LayoutNonMember,
    LayoutNonMemberIntegration,
    LayoutWithMenu,
    LayoutWithMenuIntegration,
} from './Components/Layout';
import Login from './Components/Login';
import Notifications from './Components/Notifications';
import { InventoryPermission } from './Components/Permission';
import PrivateRoute from './Components/PrivateRoute';
import ResetPassword from './Components/ResetPassword';
import RoleRoute from './Components/RoleRoute';
import { TelemetryProvider } from './Components/Telemetry';
import { ApiAccess } from './Components/User';
import config from './config';
import { useGaTracking } from './Helpers/analytics';
import { useBrandedPortal } from './Helpers/hooks/useBrandedPortal';
import customTheme from './Theme';

function App() {
    useGaTracking(config.ga.id);
    const theme = useTheme();
    const defaultToggleValue = JSON.parse(sessionStorage.getItem('notificationsToggle') || 'true');
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [menuExpanded, setMenuExpanded] = useState<boolean>(!isSmallScreen);
    const [notificationsToggle, setNotificationsToggle] = useState<boolean>(defaultToggleValue);
    useEffect(() => {
        setMenuExpanded(!isSmallScreen);
    }, [isSmallScreen]);

    const updateNotificationsToggle = () => {
        setNotificationsToggle(!notificationsToggle);
        sessionStorage.setItem('notificationsToggle', (!notificationsToggle).toString());
    };

    return (
        <>
            <FaviconManager />
            <Switch>
                <Route exact path="/integration/login">
                    <AuthConsumer>
                        {({ isAuthenticated }) => {
                            return isAuthenticated() ? (
                                <Redirect to="/integration/dashboard" />
                            ) : (
                                <LayoutNonMemberIntegration>
                                    <Login redirectUrl="/integration/dashboard"></Login>
                                </LayoutNonMemberIntegration>
                            );
                        }}
                    </AuthConsumer>
                </Route>
                <Route exact path="/login">
                    <AuthConsumer>
                        {({ isAuthenticated }) => {
                            return isAuthenticated() ? (
                                <Redirect to="/" />
                            ) : (
                                <LayoutNonMember>
                                    <Login></Login>
                                </LayoutNonMember>
                            );
                        }}
                    </AuthConsumer>
                </Route>
                <Route exact path="/reset-password/:userId/:token+">
                    <LayoutNonMember>
                        <ResetPassword mode="reset"></ResetPassword>
                    </LayoutNonMember>
                </Route>
                <Route exact path="/create-password/:userId/:token+">
                    <LayoutNonMember>
                        <ResetPassword mode="create"></ResetPassword>
                    </LayoutNonMember>
                </Route>
                <Route exact path="/integration/forgot-password">
                    <LayoutNonMemberIntegration>
                        <ForgotPassword loginUrl="/integration/login"></ForgotPassword>
                    </LayoutNonMemberIntegration>
                </Route>
                <Route exact path="/forgot-password">
                    <LayoutNonMember>
                        <ForgotPassword></ForgotPassword>
                    </LayoutNonMember>
                </Route>
                <RoleRoute
                    role={['Admin', 'Brand Admin']}
                    path="/admin"
                    render={({ match: { url } }: RouteComponentProps) => (
                        <LayoutWithMenu
                            menuExpanded={menuExpanded}
                            onToggleMenu={setMenuExpanded}
                            updateNotificationsToggle={updateNotificationsToggle}
                            notificationsToggle={notificationsToggle}
                        >
                            <Admin url={url}></Admin>
                        </LayoutWithMenu>
                    )}
                ></RoleRoute>

                <PrivateRoute
                    loginUrl="/integration/login"
                    path="/integration"
                    render={({ match: { url } }: RouteComponentProps) => (
                        <LayoutWithMenuIntegration
                            url={url}
                            updateNotificationsToggle={updateNotificationsToggle}
                            notificationsToggle={notificationsToggle}
                        ></LayoutWithMenuIntegration>
                    )}
                ></PrivateRoute>

                <PrivateRoute
                    path="/inventory"
                    render={({ match: { url } }: RouteComponentProps) => (
                        <InventoryPermission>
                            <LayoutWithMenu
                                menuExpanded={menuExpanded}
                                onToggleMenu={setMenuExpanded}
                                updateNotificationsToggle={updateNotificationsToggle}
                                notificationsToggle={notificationsToggle}
                            >
                                <Route exact path={`${url}/active`}>
                                    <ActiveInventory menuExpanded={menuExpanded} />
                                </Route>
                                <Route exact path={`${url}/sold`}>
                                    <SoldInventory menuExpanded={menuExpanded} />
                                </Route>
                                <Route exact path={`${url}/all`}>
                                    <AllInventory menuExpanded={menuExpanded} />
                                </Route>
                                <Route exact path={`${url}/dashboard`}>
                                    <MyInventory menuExpanded={menuExpanded} />
                                </Route>
                            </LayoutWithMenu>
                        </InventoryPermission>
                    )}
                ></PrivateRoute>

                <PrivateRoute
                    path="/user"
                    render={({ match: { url } }: RouteComponentProps) => (
                        <LayoutWithMenu
                            menuExpanded={menuExpanded}
                            onToggleMenu={setMenuExpanded}
                            updateNotificationsToggle={updateNotificationsToggle}
                            notificationsToggle={notificationsToggle}
                        >
                            <Route exact path={`${url}/apiaccess`}>
                                <AuthConsumer>
                                    {({ isAuthenticated, getUser }) => {
                                        const user = getUser();
                                        return isAuthenticated() && user ? <ApiAccess user={user} /> : null;
                                    }}
                                </AuthConsumer>
                            </Route>
                        </LayoutWithMenu>
                    )}
                ></PrivateRoute>

                <PrivateRoute
                    path="/"
                    render={({ match: { url } }: RouteComponentProps) => (
                        <LayoutWithMenu
                            menuExpanded={menuExpanded}
                            onToggleMenu={setMenuExpanded}
                            updateNotificationsToggle={updateNotificationsToggle}
                            notificationsToggle={notificationsToggle}
                        >
                            <Route exact path={`${url}active-leads`}>
                                <ActiveLeads menuExpanded={menuExpanded} />
                            </Route>
                            <Route exact path={`${url}stalled-leads`}>
                                <StalledLeads menuExpanded={menuExpanded} />
                            </Route>
                            <Route exact path={`${url}active-transactions`}>
                                <ActiveTransactions menuExpanded={menuExpanded} />
                            </Route>
                            <Route exact path={`${url}stalled-transactions`}>
                                <StalledTransactions menuExpanded={menuExpanded} />
                            </Route>
                            <Route exact path={`${url}funded-transactions`}>
                                <FundedTransactions menuExpanded={menuExpanded} />
                            </Route>
                            <Route exact path={`${url}all-leads`}>
                                <AllLeads menuExpanded={menuExpanded} />
                            </Route>
                            <Route exact path={`${url}dashboard`}>
                                <MyPipeline menuExpanded={menuExpanded} />
                            </Route>
                            <AuthConsumer>
                                {({ isAuthenticated, getUser }) => {
                                    const financeAppUrl = getUser()?.financeApplicationUrl || '';
                                    return isAuthenticated() && financeAppUrl.length > 0 ? (
                                        <Route exact path={`${url}finance-application`}>
                                            <FinanceApp financeAppUrl={financeAppUrl} />
                                        </Route>
                                    ) : null;
                                }}
                            </AuthConsumer>

                            <Route exact path={`${url}`}>
                                <MyPipeline menuExpanded={menuExpanded} />
                            </Route>
                        </LayoutWithMenu>
                    )}
                ></PrivateRoute>

                <Route>Page Not Found</Route>
            </Switch>
            <AuthConsumer>
                {({ isAuthenticated, getAccessToken }) => {
                    return isAuthenticated() ? (
                        <>
                            {notificationsToggle && <Notifications accessToken={getAccessToken()}></Notifications>}
                            <LogoutListener accessToken={getAccessToken()}></LogoutListener>
                        </>
                    ) : null;
                }}
            </AuthConsumer>
        </>
    );
}

export default () => {
    const brandedPortal = useBrandedPortal();
    const isBrandedPortal = brandedPortal.isBrandedPortal;
    const theme = customTheme(isBrandedPortal);

    return (
        <>
            <Router>
                {/* @ts-expect-error  TelemetryProvider */}
                <TelemetryProvider instrumentationKey={config.ai.key}>
                    <GoogleAnalytics />
                    <AuthProvider>
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={theme}>
                                <CssBaseline />
                                <App />
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </AuthProvider>
                </TelemetryProvider>
            </Router>
        </>
    );
};
