import React from 'react';
import config from '../../config';

export default class GoogleAnalytics extends React.Component {
    initGa() {
        if (window.dataLayer) {
            window.dataLayer = window.dataLayer || [];
            window.gtag = function gtag(...args: unknown[]) {
                window.dataLayer.push(args);
            };
            window.gtag('js', new Date());
            window.gtag('config', config.ga.id, {
                page_path: window.location.pathname,
            });
        } else {
            setTimeout(() => {
                this.initGa();
            }, 100);
        }
    }

    setup() {
        const script = document.createElement('script');
        script.src = 'https://www.googletagmanager.com/gtag/js?id=' + config.ga.id;
        script.async = true;
        document.body.appendChild(script);

        this.initGa();
    }

    componentDidMount() {
        if (config.ga.enabled) {
            this.setup();
        }
    }

    render() {
        return <></>;
    }
}
