import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        boxShadow: '0px 10px 13px -4px rgba(204,204,204,1)',
        justifyContent: 'space-between',
        '& .MuiTabs-scrollButtons.Mui-disabled': {
            opacity: 0.3,
        },
    },
    logOut: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        minWidth: 530,
    },
    menuPosition: {
        top: '44px !important',
        left: '-11px !important',
    },
    menuItem: {
        marginLeft: 15,
    },

    tab: {
        borderRight: '1px solid #ececec',
    },
    userAvatarContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    userAvatarContent: {
        marginBottom: theme.spacing(4),
        width: theme.layout.leftDrawerWidth - 10,
    },
    userAvatarBorder: {
        position: 'absolute',
        borderColor: theme.colors.lightBlue,
        border: '4px solid',
        width: 40,
        height: 40,
        zIndex: 1,
        borderRadius: 27,
    },
    userAvatarIcon: {
        float: 'left',
        marginLeft: 14,
        height: 40,
        width: 40,
        color: theme.colors.lightBlue,
        backgroundColor: theme.palette.common.white,
        '& svg': {
            width: 44,
            height: 44,
            color: theme.colors.darkGrey,
        },
    },
    userAvatarName: {
        float: 'left',
        marginLeft: 10,
        marginTop: 5,
    },
    userAvatarDots: {
        height: 38,
        width: 38,
        float: 'right',
        marginTop: 8,
        '& svg': {
            color: theme.colors.mediumGrey,
            height: theme.spacing(9),
            width: theme.spacing(9),
        },
    },
    userAvatarDotsCollapsed: {
        float: 'left',
        height: 34,
        width: 34,
        marginTop: 0,
    },
    hidden: {
        display: 'none',
    },
    notificationsToggle: {
        paddingLeft: 0,
    },
    drawerToggleMobile: {
        zIndex: 1200,
        position: 'fixed',
        color: theme.palette.common.white,
        backgroundColor: theme.colors.darkBlue,
        height: 39,
        width: 49,
    },
    topMenuBar: {
        zIndex: 1199,
        position: 'fixed',
        backgroundColor: theme.colors.darkBlue,
        color: theme.palette.common.white,
        height: 43,
        width: '100vw',
    },
    dividerContainer: {
        height: 1,
        marginLeft: theme.spacing(14),
        marginRight: theme.spacing(14),
        marginTop: 0,
        marginBottom: 10,
        overflow: 'visible',
        '& div': {
            height: 1,
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            '& div': {
                height: 1,
                marginLeft: theme.spacing(3),
                marginRight: theme.spacing(3),
            },
        },
    },
    divider: {
        position: 'absolute',
        left: theme.spacing(9),
        marginTop: -10,
        width: 188,
        backgroundColor: theme.palette.common.white,
    },
    dividerExpanded: {
        left: theme.spacing(9),
    },
    dividerCollapsed: {
        left: 11,
        width: 78,
    },
    dividerCollapsedFull: {
        left: 0,
        width: 100,
    },
    menuIcon: {
        color: 'rgba(0, 0, 0, 0.54)',
        display: 'inline-flex',
        minWidth: 35,
        flexShrink: 0,
    },
    newAppButton: {
        color: theme.palette.common.white,
        textTransform: 'capitalize',
        width: 180,
    },
    centerAlign: {
        display: 'flex',
        justifyContent: 'center',
    },
    copyApp: {
        textTransform: 'initial',
        fontSize: 13,
        marginRight: 8,
        '& svg': {
            width: 12,
            height: 12,
        },
    },
}));

export const menuItemTooltipStyle = (theme: Theme) => ({
    backgroundColor: theme.colors.darkBlue,
    color: theme.palette.common.white,
    height: 41,
    marginLeft: 38,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '22px',
    letterSpacing: '0.28px',
});

export default useStyles;
