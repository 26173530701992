import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, LinearProgress, Box, Typography, Divider, Alert } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Formik, Form, Field } from 'formik';
import { FormikMaterialUITextField as TextField } from '../Common/TextField';
import classnames from 'classnames';
import * as Yup from 'yup';
import { postResetPassword } from '../../Api';
import useStyles from './resetPassword.style';
import { ResetPasswordResponse } from '../../Helpers/types';

interface ResetPasswordProps {
    mode: 'create' | 'reset';
}

const ResetPassword = (props: ResetPasswordProps) => {
    const { classes } = useStyles();

    const [showResetPwdError, setShowResetPwdError] = useState<boolean>(false);
    const [showResetPwdLinkExpiredError, setShowResetPwdLinkExpiredError] = useState<boolean>(false);
    const [pwdWasReset, setPwdWasReset] = useState<boolean>(false);
    const [loginUrl, setLoginUrl] = useState<string>('/login');
    const { userId, token } = useParams<{ userId: string; token: string }>();

    const passwordRules = [
        'Password must contain numbers.',
        'Password must contain uppercase letters.',
        'Password must contain lowercase letters.',
        'Password must have at least one symbol.',
        'Length must be greater than 8 characters.',
    ];

    const updateLoginUrl = function (url: string) {
        url ? setLoginUrl(url) : setLoginUrl('/login');
    };

    return (
        <Box className={classes.root}>
            {!pwdWasReset && (
                <>
                    <Typography variant="h1" className={classes.setPasswordText}>
                        {props.mode === 'create' ? 'Set a' : 'Reset'} Password
                    </Typography>
                    <Typography variant="h6" className={classes.setPasswordDetailsText}>
                        Please reset and confirm your new password. Please also note the password restrictions outlined
                        below the Set a Password button prior to attempting to create your password. These restrictions
                        are in place to keep your data safe from hackers or anyone attempting to gain unauthorized
                        access to your information.
                    </Typography>
                    <Formik
                        initialValues={{
                            password: '',
                            passwordConfirm: '',
                        }}
                        validationSchema={Yup.object().shape({
                            password: Yup.string()
                                .required('Required')
                                .min(8, 'Length must be greater than 8 characters')
                                .matches(/[0-9]/, 'Password must contain numbers')
                                .matches(/[a-z]/, 'Password must contain lowercase letters')
                                .matches(/[A-Z]/, 'Password must contain uppercase letters')
                                .matches(
                                    /[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/,
                                    'Password must have at least one symbol',
                                ),
                            passwordConfirm: Yup.string()
                                .required('Required')
                                .oneOf([Yup.ref('password'), null], 'Passwords do not match'),
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            setShowResetPwdError(false);
                            setShowResetPwdLinkExpiredError(false);
                            const data = Object.assign(values, {
                                passwordResetToken: token,
                                userId,
                            });
                            postResetPassword(
                                data,
                                (response) => {
                                    updateLoginUrl(response as ResetPasswordResponse);
                                    setSubmitting(false);
                                    setPwdWasReset(true);
                                },
                                (err) => {
                                    if (err.response?.status === 410) {
                                        setShowResetPwdLinkExpiredError(true);
                                    } else {
                                        setShowResetPwdError(true);
                                    }
                                    setSubmitting(false);
                                },
                            );
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form className={classes.form}>
                                <Field
                                    component={TextField}
                                    name="password"
                                    type="password"
                                    label="New Password"
                                    variant="standard"
                                    size="medium"
                                    margin="normal"
                                    className={classes.formField}
                                ></Field>
                                <Field
                                    component={TextField}
                                    type="password"
                                    label="Confirm New Password"
                                    name="passwordConfirm"
                                    variant="standard"
                                    size="medium"
                                    margin="normal"
                                    className={classes.formField}
                                />
                                {isSubmitting && <LinearProgress />}
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    className={classes.button}
                                >
                                    {props.mode === 'create' ? 'Set a' : 'Change my'} Password
                                </Button>
                            </Form>
                        )}
                    </Formik>
                    <Box
                        className={classnames(classes.alert, {
                            [classes.visible]: showResetPwdError,
                            [classes.hidden]: !showResetPwdError,
                        })}
                    >
                        <Alert severity="error">
                            <Typography variant="h6">Please try again</Typography>
                        </Alert>
                    </Box>
                    <Box
                        className={classnames(classes.alert, {
                            [classes.visible]: showResetPwdLinkExpiredError,
                            [classes.hidden]: !showResetPwdLinkExpiredError,
                        })}
                    >
                        <Alert severity="error">
                            <Typography variant="h6">
                                Password reset link has expired, generate a new one{' '}
                                <Link to="/forgot-password">here</Link>.
                            </Typography>
                        </Alert>
                    </Box>
                    <Divider
                        variant="fullWidth"
                        className={classnames(classes.divider, {
                            [classes.dividerWithAlert]: showResetPwdError,
                        })}
                    ></Divider>
                    <Box
                        className={classnames({
                            [classes.rulesNoAlert]: !showResetPwdError,
                            [classes.rulesWithAlert]: showResetPwdError,
                        })}
                    >
                        {passwordRules.map((rule, index) => (
                            <Typography className={classes.ruleText} variant="h6" key={index}>
                                <span className={classes.rulePrefix}>-</span>
                                {rule}
                            </Typography>
                        ))}
                    </Box>
                </>
            )}
            {pwdWasReset && (
                <>
                    <Box className={classes.welcomeTitle}>
                        <Typography variant="h1">Welcome</Typography>
                        <CheckCircleOutlineIcon
                            color="secondary"
                            className={classes.welcomeIcon}
                        ></CheckCircleOutlineIcon>
                    </Box>
                    <Typography variant="h6" className={classes.welcomeDetailsText}>
                        Welcome to the Taycor Partner Portal. Here you can easily track your leads throughout their
                        finance experience. You can also us the chat feature to communicate with a finance concierge
                        about deals or request information about any of Taycor’s portfolio of products. We look forward
                        to helping you to drive your business to new heights!
                    </Typography>
                    <Box className={classes.pwdReset}>
                        <Button
                            variant="contained"
                            type="button"
                            color="primary"
                            className={classnames(classes.button, {
                                [classes.buttonLogin]: pwdWasReset,
                            })}
                            href={loginUrl}
                        >
                            Log In
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ResetPassword;
