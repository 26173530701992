import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    root: {
        display: 'flex',
        flexGrow: 1,
    },
    rightHalf: {
        flex: 0.5,
        paddingTop: '10%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export default useStyles;
