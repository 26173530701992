import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import config from '../config';

let reactPlugin: ReactPlugin | null = null;
let appInsights: ApplicationInsights | null = null;

const createTelemetryService = () => {
    const initialize = (instrumentationKey: string, browserHistory: History) => {
        if (!browserHistory) {
            throw new Error('Could not initialize telemetry service');
        }
        if (!instrumentationKey) {
            throw new Error('Instrumentation key not provided');
        }

        reactPlugin = new ReactPlugin();

        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                disableTelemetry: config.ai.disabled || false,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history: browserHistory,
                    },
                },
            },
        });

        appInsights.loadAppInsights();
    };

    return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;
