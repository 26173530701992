import React, { useEffect } from 'react';
import * as signalR from '@microsoft/signalr';
import config from '../../config';
import logger from '../../Helpers/logger';
import auth from '../../Services/auth';

interface LogoutListenerProps {
    accessToken: string | null;
}

function LogoutListener(props: LogoutListenerProps) {
    useEffect(() => {
        if (!props.accessToken) return;

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(config.api.url + '/hubs/accounts', {
                accessTokenFactory: () => props.accessToken + '',
            })
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Warning)
            .build();

        connection.on('Logout', () => {
            auth.signout();
        });

        connection.onclose((err) => {
            logger.logError(err, '[accounts] connection closed');
        });

        const startConnection = () =>
            connection.start().catch((err) => {
                logger.logError(err, '[accounts] error connecting');
                setTimeout(() => {
                    startConnection();
                }, 5000);
            });

        startConnection();
    });
    return <></>;
}

export default LogoutListener;
