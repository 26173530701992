import React from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { AuthConsumer } from '../Auth';
interface PrivateRouteeProps {
    children?: React.ReactNode;
    loginUrl?: string;
    path: string;
    render: ({ match: { url } }: RouteComponentProps) => JSX.Element;
}
function PrivateRoute(props: PrivateRouteeProps) {
    const { children, loginUrl, ...rest } = props;
    return (
        <AuthConsumer>
            {({ isAuthenticated }) => {
                if (isAuthenticated()) {
                    return <Route {...rest}>{children}</Route>;
                } else if (loginUrl) {
                    return <Redirect to={loginUrl}></Redirect>;
                } else {
                    return <Redirect to="/login"></Redirect>;
                }
            }}
        </AuthConsumer>
    );
}

export default PrivateRoute;
