import React from 'react';
import Box from '@mui/material/Box';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    className?: string;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, className, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} {...other} className={className}>
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

export default TabPanel;
