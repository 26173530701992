import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    drawerPaper: {
        boxShadow: '-5px 5px 10px 0 rgba(0, 0, 0, 0.05)',
        border: 'none',
        '& .simplebar-vertical': {
            marginTop: 30,
            right: 0,
        },
    },
    tabs: {
        paddingLeft: theme.rightDrawer.spacing.left,
        paddingRight: theme.rightDrawer.spacing.right,
        marginTop: 70,
        '& .MuiTab-root': {
            borderBottom: `3px solid ${theme.colors.tabsBottomBorder}`,
            minWidth: theme.layout.rightDrawerTabWidth,
            [theme.breakpoints.down('md')]: {
                minWidth: theme.layout.rightDrawerTabNarrowWidth,
            },
            '& span': {
                fontSize: 18,
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 1.11,
                letterSpacing: 'normal',
                textTransform: 'capitalize',
            },
            '&.Mui-selected': {
                '& span': {
                    fontWeight: 'bold',
                },
            },
        },
        '& .MuiTabs-indicator': {
            height: 3,
            width: '100% !important',
            left: '0 !important',
        },
        '& .MuiTabs-scroller': {
            display: 'flex',
            justifyContent: 'center',
        },
    },
    tabPanel: {
        paddingLeft: theme.rightDrawer.spacing.left,
        paddingRight: theme.rightDrawer.spacing.right,
        width: theme.layout.rightDrawerWidth,
        [theme.breakpoints.down('md')]: {
            width: theme.layout.rightDrawerNarrowWidth,
        },
    },
    closeIcon: {
        position: 'fixed',
        top: 0,
        right: 0,
        zIndex: 1,
        '& .MuiButton-startIcon': {
            marginLeft: 0,
            marginRight: 0,
        },
        '& .MuiButton-root': {
            width: 30,
            minWidth: 30,
            borderRadius: 0,
            borderBottomLeftRadius: 6,
        },
    },
    simplebar: {
        maxHeight: '100vh',
        width: theme.layout.rightDrawerWidth,
        [theme.breakpoints.down('md')]: {
            width: theme.layout.rightDrawerNarrowWidth,
        },
    },
}));

export default useStyles;
