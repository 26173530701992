import { AxiosError } from 'axios';
import config from '../config';
import { checkForUserPVD } from '../Helpers/hooks/checkForUserPVD';
import { ActivityType, AuthLoginPayload, Id, ParentVendorDealer, ResetPasswordPayload } from '../Helpers/types';
import { AddUserPayload, User } from '../Services/auth';
import fakeDataAll from './fakes/all.fake';
import { CustomError, del, get, patchWithFiles, post, postWithFiles } from './fetch-portal-api';

const postLogin = (
    data: AuthLoginPayload,
    success: <AuthLogin>(data: AuthLogin) => void,
    error: (err: AxiosError) => void,
) => {
    post('/accounts/login', data, success, error);
};

const postRefreshToken = (
    refreshToken: string | null,
    success: <AuthLogin>(data: AuthLogin) => void,
    error: (err: AxiosError) => void,
) => {
    post('/accounts/refreshtoken', { refreshToken }, success, error);
};

const postLogout = (
    refreshToken: string | null,
    success: <AuthLogout>(data: AuthLogout) => void,
    error: (err: AxiosError) => void,
) => {
    post('/accounts/logout', { refreshToken }, success, error);
};

const getAll = (success: <Data>(data: Data | Data[]) => void, error: (err: AxiosError) => void) => {
    const emptyObject: ParentVendorDealer[] = [];
    post(
        `/dealpipelines/leads/all`,
        checkForUserPVD() || config.pvd.enabled
            ? {
                  Vendors: '',
                  LeadSourceIds: getVendorsParameter(),
                  BrandIds: getBidsParameter(),
                  OIDs: getOidsParameter(),
              }
            : { Vendors: getVendorsParameter(), LeadSourceIds: emptyObject },
        success,
        error,
        fakeDataAll,
    );
};

const getActiveLeads = (success: <Data>(data: Data | Data[]) => void, error: (err: AxiosError) => void) => {
    const emptyObject: ParentVendorDealer[] = [];
    post(
        `/dealpipelines/leads/active`,
        checkForUserPVD() || config.pvd.enabled
            ? {
                  Vendors: '',
                  LeadSourceIds: getVendorsParameter(),
                  BrandIds: getBidsParameter(),
                  OIDs: getOidsParameter(),
              }
            : { Vendors: getVendorsParameter(), LeadSourceIds: emptyObject },
        success,
        error,
        fakeDataAll,
    );
};

const getStalledLeads = (success: <Data>(data: Data | Data[]) => void, error: (err: AxiosError) => void) => {
    const emptyObject: ParentVendorDealer[] = [];
    post(
        `/dealpipelines/leads/stalled`,
        checkForUserPVD() || config.pvd.enabled
            ? {
                  Vendors: '',
                  LeadSourceIds: getVendorsParameter(),
                  BrandIds: getBidsParameter(),
                  OIDs: getOidsParameter(),
              }
            : { Vendors: getVendorsParameter(), LeadSourceIds: emptyObject },
        success,
        error,
        fakeDataAll,
    );
};

const getActiveTransactions = (success: <Data>(data: Data | Data[]) => void, error: (err: AxiosError) => void) => {
    const emptyObject: ParentVendorDealer[] = [];
    post(
        '/dealpipelines/transactions/active',
        checkForUserPVD() || config.pvd.enabled
            ? {
                  Vendors: '',
                  LeadSourceIds: getVendorsParameter(),
                  BrandIds: getBidsParameter(),
                  OIDs: getOidsParameter(),
              }
            : { Vendors: getVendorsParameter(), LeadSourceIds: emptyObject },
        success,
        error,
        fakeDataAll,
    );
};

const getStalledTransactions = (success: <Data>(data: Data | Data[]) => void, error: (err: AxiosError) => void) => {
    const emptyObject: ParentVendorDealer[] = [];

    post(
        `/dealpipelines/transactions/stalled`,
        checkForUserPVD() || config.pvd.enabled
            ? {
                  Vendors: '',
                  LeadSourceIds: getVendorsParameter(),
                  BrandIds: getBidsParameter(),
                  OIDs: getOidsParameter(),
              }
            : { Vendors: getVendorsParameter(), LeadSourceIds: emptyObject },
        success,
        error,
        fakeDataAll,
    );
};

const getFundedTransactions = (success: <Data>(data: Data | Data[]) => void, error: (err: AxiosError) => void) => {
    const emptyObject: ParentVendorDealer[] = [];

    post(
        `/dealpipelines/transactions/funded`,
        checkForUserPVD() || config.pvd.enabled
            ? {
                  Vendors: '',
                  LeadSourceIds: getVendorsParameter(),
                  BrandIds: getBidsParameter(),
                  OIDs: getOidsParameter(),
              }
            : { Vendors: getVendorsParameter(), LeadSourceIds: emptyObject },
        success,
        error,
        fakeDataAll,
    );
};

const getUsers = (success: <User>(data: User | User[]) => void, error: (err: AxiosError) => void) => {
    get('/accounts', success, error);
};

const getBrandUsers = (
    brand: string,
    success: <User>(data: User | User[]) => void,
    error: (err: AxiosError) => void,
) => {
    get(`/accounts/${brand}`, success, error);
};

const postAddUser = (
    data: AddUserPayload,
    file: File | null,
    success: <AddUpdateUserResponse>(data: AddUpdateUserResponse) => void,
    error: (err: AxiosError) => void,
) => {
    postWithFiles('/accounts', data, { companyLogoFile: file }, success, error);
};

const patchUpdateUser = (
    data: Partial<User>,
    file: File | null,
    success: <AddUpdateUserResponse>(data: AddUpdateUserResponse) => void,
    error: (err: CustomError) => void,
) => {
    patchWithFiles('/accounts', data, { companyLogoFile: file }, success, error);
};

const postResetPassword = (
    data: ResetPasswordPayload,
    success: <ResetPasswordResponse>(data: ResetPasswordResponse) => void,
    error: (err: AxiosError) => void,
) => {
    post('/accounts/resetpassword', data, success, error);
};

const postForgotPassword = (
    emailAddress: string,
    brand: string,
    success: (data: unknown) => void,
    error: (err: AxiosError) => void,
) => {
    post('/accounts/forgotpassword', { emailAddress, brand }, success, error);
};

const getBrands = (success: <Brand>(data: Brand | Brand[]) => void, error: (err: AxiosError) => void) => {
    get('/brands', success, error);
};

const getBrandIds = (success: <BrandId>(data: BrandId | BrandId[]) => void, error: (err: AxiosError) => void) => {
    get('/brandids', success, error);
};
const getOids = (success: <Id>(data: Id | Id[]) => void, error: (err: AxiosError) => void) => {
    get('/oids', success, error);
};

const getVendors = (success: <Vendor>(data: Vendor | Vendor[]) => void, error: (err: AxiosError) => void) => {
    get('/vendors', success, error);
};

const getParentVendorDealer = (
    success: <ParentVendorDealer>(data: ParentVendorDealer | ParentVendorDealer[]) => void,
    error: (err: AxiosError) => void,
) => {
    get('/parentvendordealer', success, error);
};

const getParentVendorDealerWithBrand = (
    brandIds: string[],
    success: <ParentVendorDealer>(data: ParentVendorDealer | ParentVendorDealer[]) => void,
    error: (err: AxiosError) => void,
) => {
    post(`/parentvendordealer/pidsandvids`, { BrandIds: brandIds }, success, error, fakeDataAll);
};

const getDidsAndRidsWithBrand = (
    brandIds: string[],
    PidsAndVids: string[],
    success: <ParentVendorDealer>(data: ParentVendorDealer | ParentVendorDealer[]) => void,
    error: (err: AxiosError) => void,
) => {
    post(
        `/parentvendordealer/didsandrids`,
        { BrandIds: brandIds, LeadSourceIds: PidsAndVids },
        success,
        error,
        fakeDataAll,
    );
};
const getRidsWithBrand = (
    brandIds: string[],
    dids: string[],
    success: <ParentVendorDealer>(data: ParentVendorDealer | ParentVendorDealer[]) => void,
    error: (err: AxiosError) => void,
) => {
    post(`/parentvendordealer/rids`, { BrandIds: brandIds, LeadSourceIds: dids }, success, error, fakeDataAll);
};

const getCompanyAndTaycorRep = (
    activityId: string,
    activityType: ActivityType,
    success: <CompanyData>(data: CompanyData) => void,
    error: (err: AxiosError) => void,
) => {
    get(`/${activityType}s/${activityId}/companyandrep`, success, error);
};

const getMilestones = (
    activityId: string,
    activityType: ActivityType,
    success: <Milestone>(data: Milestone) => void,
    error: (err: AxiosError) => void,
) => {
    get(`/${activityType}s/${activityId}/milestones`, success, error);
};

const getMyPipeline = (success: <MyPipelineData>(data: MyPipelineData) => void, error: (err: AxiosError) => void) => {
    get(`/dashboards/mypipeline`, success, error);
};

const getActiveInventory = (success: <Data>(data: Data | Data[]) => void, error: (err: AxiosError) => void) => {
    get(`/inventorytracker/active?vendors=${getVendorsParameter()}`, success, error, fakeDataAll);
};

const getSoldInventory = (success: <Data>(data: Data | Data[]) => void, error: (err: AxiosError) => void) => {
    get(`/inventorytracker/sold?vendors=${getVendorsParameter()}`, success, error, fakeDataAll);
};

const getAllInventory = (success: <Data>(data: Data | Data[]) => void, error: (err: AxiosError) => void) => {
    get(`/inventorytracker/all?vendors=${getVendorsParameter()}`, success, error, fakeDataAll);
};

const getInventoryItemDetails = (
    itemId: string,
    success: <InventoryItemDetails>(data: InventoryItemDetails) => void,
    error: (err: AxiosError) => void,
) => {
    get(`/inventorytracker/${itemId}/details`, success, error);
};

const getMyInventory = (
    success: <MyInventoryItem>(data: MyInventoryItem | MyInventoryItem[]) => void,
    error: (err: AxiosError) => void,
) => {
    get(`/dashboards/myinventory`, success, error);
};

const postCreateUploadInstance = (
    itemId: string,
    itemType: 'lead' | 'transaction',
    success: <UploadInstance>(data: UploadInstance) => void,
    error: (err: AxiosError) => void,
) => {
    post(`/uploads/${itemType}/${itemId}`, {}, success, error);
};

const deleteUploadInstance = (
    itemId: string,
    itemType: 'lead' | 'transaction',
    success: (data: unknown) => void,
    error: (err: AxiosError) => void,
) => {
    del(`/uploads/${itemType}/${itemId}`, success, error);
};

const postSetupApiAccess = (
    userId: string,
    success: <ApiAccessDetails>(data: ApiAccessDetails) => void,
    error: (err: AxiosError) => void,
) => {
    post(`/accounts/${userId}/apiaccess`, {}, success, error);
};

const getVendorsParameter = () => {
    if (checkForUserPVD() || config.pvd.enabled) {
        const incomingPVDs: ParentVendorDealer[] = JSON.parse(sessionStorage.getItem('vendors') || '[]');
        return incomingPVDs.map((p) => p.id);
    }
    return encodeURIComponent(JSON.parse(sessionStorage.getItem('vendors') || '[]').join(',')).substr(0, 1500);
};
const getBidsParameter = () => {
    if (checkForUserPVD() || config.pvd.enabled) {
        const incomingBIDs: Id[] = JSON.parse(sessionStorage.getItem('bids') || '[]');
        return incomingBIDs.map((p) => p.name);
    }
};
const getOidsParameter = () => {
    if (checkForUserPVD() || config.pvd.enabled) {
        const incomingOIDs: Id[] = JSON.parse(sessionStorage.getItem('oids') || '[]');
        return incomingOIDs.map((p) => p.name);
    }
};

export {
    postLogin,
    postRefreshToken,
    postLogout,
    getAll,
    getActiveLeads,
    getStalledLeads,
    getActiveTransactions,
    getStalledTransactions,
    getFundedTransactions,
    getUsers,
    postAddUser,
    patchUpdateUser,
    postResetPassword,
    postForgotPassword,
    getVendors,
    getParentVendorDealer,
    getParentVendorDealerWithBrand,
    getDidsAndRidsWithBrand,
    getBrands,
    getCompanyAndTaycorRep,
    getMilestones,
    getMyPipeline,
    getActiveInventory,
    getSoldInventory,
    getInventoryItemDetails,
    getMyInventory,
    getAllInventory,
    postCreateUploadInstance,
    deleteUploadInstance,
    postSetupApiAccess,
    getBrandUsers,
    getBrandIds,
    getOids,
    getRidsWithBrand,
};
