import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    table: {
        marginTop: 28,
        '&.MuiPaper-elevation1': {
            boxShadow: 'none',
        },
        '&.MuiPaper-rounded': {
            borderRadius: 0,
        },
        '& .MuiTableRow-root:not(.MuiTableRow-head):nth-of-type(odd)': {
            backgroundColor: theme.colors.lightGrey,
        },
        '& .MuiTableCell-root': {
            borderBottom: 'none',
        },
    },
    tableTitleRow: {
        height: 22,
    },
    tableTitleRowSpacingSm: {
        '& .MuiTableCell-root': {
            padding: '0px 0px 5px 0px',
        },
    },
    tableTitleRowSpacingMd: {
        '& .MuiTableCell-root': {
            padding: '0px 0px 18px 0px',
        },
    },
    tableTitleRowSpacingLg: {
        '& .MuiTableCell-root': {
            padding: '0px 0px 21px 0px',
        },
    },
    tableSubtitleRow: {
        height: 20,
        '& .MuiTableCell-root': {
            padding: '0px 0px 18px 0px',
        },
    },
    tableLabelCell: {
        minWidth: 154,
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
        '&.MuiTableCell-root': {
            paddingLeft: theme.spacing(1),
        },
    },
    tableLabelCellText: {
        fontWeight: 'bold',
    },
    tableValueCell: {
        minWidth: 226,
        maxWidth: 226,
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
        '&.MuiTableCell-root': {
            paddingRight: theme.spacing(1),
        },
    },
}));

export default useStyles;
