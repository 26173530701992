import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Box, Button, Checkbox, ListItem, TextField, Typography, useTheme } from '@mui/material';
import { SortingState } from '@tanstack/table-core';
import { DefaultThemeProvider } from '@taycor/ui-library';
import classNames from 'classnames';
import { MaterialReactTable as MaterialTable, MRT_ColumnDef as Column } from 'material-react-table';
import { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import config from '../../../config';
import { Data } from '../../../Helpers/deal-pipelines';
import { checkForUserPVD } from '../../../Helpers/hooks/checkForUserPVD';
import { ActivityType, Id, ParentVendorDealer, Vendor } from '../../../Helpers/types';
import { collapseUserLeadSources, expandUserLeadSources } from '../../../Helpers/users';
import { compareStrings } from '../../../Helpers/utils';
import { CompanyLogo } from '../../Common';
import Permission from '../../Permission';
import { columnsPVD } from '../ActiveLeads/config';
import useStyles from './baseView.style';
import LeadSourceFilter from './children/LeadSourceFilter';
import Details from './Details';
import InventoryDetails from './InventoryDetails';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface SelectedItem {
    id: string;
    type: ActivityType;
}

interface BaseViewProps {
    title: string;
    subtitle: string;
    columns: Array<Column<Data>>;
    columnsPVD: Array<Column<Data>>;
    data: Array<Data>;
    isLoading: boolean;
    vendors: Array<Vendor>;
    onReload: () => void;
    menuExpanded: boolean;
    hiddenColumns: Record<string, boolean>;
    defaultSorting?: SortingState;
}

function BaseView(props: BaseViewProps) {
    const { classes } = useStyles();
    const theme = useTheme();

    const { columns, title, subtitle, menuExpanded } = props;
    const [columnVisibility, setColumnVisibility] = useState(props?.hiddenColumns || {});
    const [vendors, setVendors] = useState<Array<ParentVendorDealer>>([]);
    const [bids, setBids] = useState<Array<Id>>([]);
    const [oids, setOids] = useState<Array<Id>>([]);
    const [oldVendors, setOldVendors] = useState<Array<string>>([]);
    const [selectedItem, setSelectedItem] = useState<SelectedItem | null>(null);
    const [pagination, setPagination] = useState({
        pageSize: 50,
        pageIndex: 0,
    });

    const isIntegration = window.location.href.indexOf('integration') !== -1;
    const vendorOptions = props.vendors.map((v) => v.name).sort(compareStrings);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pagination.pageIndex, pagination.pageSize]);

    useEffect(() => {
        if (!(checkForUserPVD() || config.pvd.enabled)) {
            const defaultVendors = expandUserLeadSources(
                JSON.parse(sessionStorage.getItem('vendors') || '[]'),
                props.vendors,
            );
            setOldVendors(defaultVendors);
        }
    }, [props.vendors]);

    useEffect(() => {
        if (checkForUserPVD() || config.pvd.enabled) {
            sessionStorage.setItem('bids', JSON.stringify(bids));
            const sessionItems = sessionStorage.getItem('bids');
            if (sessionItems && bids.length > 0) {
                props.onReload();
            }
        }
    }, [bids]);

    useEffect(() => {
        if (checkForUserPVD() || config.pvd.enabled) {
            sessionStorage.setItem('oids', JSON.stringify(oids));
            const sessionItems = sessionStorage.getItem('oids');
            if (sessionItems && oids.length > 0) {
                props.onReload();
            }
        }
    }, [oids]);

    useEffect(() => {
        if (checkForUserPVD() || config.pvd.enabled) {
            sessionStorage.setItem('vendors', JSON.stringify(vendors));
            const sessionItems = sessionStorage.getItem('vendors');
            if (sessionItems && vendors.length > 0) {
                props.onReload();
            }
        } else {
            sessionStorage.setItem('vendors', JSON.stringify(collapseUserLeadSources(oldVendors)));
        }
    }, [vendors]);

    const handlePVDIds = (values: ParentVendorDealer[], bids: Id[], oids: Id[]) => {
        setBids([...bids]);
        setOids([...oids]);
        setVendors([...values]);
    };

    const search = () => {
        if (checkForUserPVD() || config.pvd.enabled) {
            // new filter
            return (
                <DefaultThemeProvider>
                    <LeadSourceFilter handlePVDIds={handlePVDIds}></LeadSourceFilter>
                </DefaultThemeProvider>
            );
        } else {
            // older version of filtering
            return (
                <>
                    <Autocomplete
                        className={classes.autocomplete}
                        multiple
                        options={vendorOptions}
                        disableCloseOnSelect
                        value={oldVendors}
                        classes={{
                            option: classes.autocompleteOption,
                        }}
                        renderInput={(props) => <TextField {...props} />}
                        renderOption={(rennderProps, option: string, { selected }) => {
                            return (
                                <>
                                    <ListItem>
                                        <Checkbox
                                            icon={icon}
                                            key={rennderProps.key}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                            onChange={() => {
                                                const newVendors: string[] = expandUserLeadSources(
                                                    [...oldVendors, option],
                                                    props.vendors,
                                                    [...oldVendors],
                                                );
                                                setOldVendors(newVendors);
                                            }}
                                        />
                                        {option}
                                    </ListItem>
                                </>
                            );
                        }}
                    ></Autocomplete>
                    <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={props.onReload}
                        className={classes.actionsButton}
                    >
                        Reload
                    </Button>
                </>
            );
        }
    };

    return (
        <Box
            className={classNames(classes.root, {
                [classes.rootDrawerOpen]: !!selectedItem,
            })}
        >
            <SimpleBar
                className={classNames(classes.simplebar, {
                    [classes.simplebarIntegration]: isIntegration,
                })}
            >
                <CompanyLogo menuExpanded={props.menuExpanded}></CompanyLogo>
                <Box
                    className={classNames(classes.tableContainer, {
                        [classes.tableContainerMenuCollapsed]: !selectedItem && !menuExpanded,
                        [classes.tableContainerNarrow]: !!selectedItem && menuExpanded,
                        [classes.tableContainerNarrowMenuCollapsed]: !!selectedItem && !menuExpanded,
                        [classes.tableContainerIntegration]: isIntegration,
                    })}
                >
                    <Permission role="Admin">
                        <Box className={classes.actions}>
                            <Typography className={classes.actionsLabel} variant="h6">
                                {checkForUserPVD() || config.pvd.enabled ? 'Filter results:' : 'Vendors'}
                            </Typography>
                            {search()}
                        </Box>
                    </Permission>
                    <MaterialTable
                        columns={
                            checkForUserPVD() || config.pvd.enabled
                                ? columns
                                : columns.filter((c) => !columnsPVD.find((cp) => c.accessorKey === cp.accessorKey))
                        }
                        data={props.data}
                        initialState={{
                            sorting: props.defaultSorting || [],
                        }}
                        state={{
                            density: 'compact',
                            columnVisibility,
                            isLoading: props.isLoading,
                            pagination,
                            showGlobalFilter: true,
                        }}
                        muiTablePaperProps={{
                            elevation: 0, //change the mui box shadow
                            sx: {
                                '& button[aria-label="Show/Hide search"]': {
                                    display: 'none',
                                },
                            },
                        }}
                        muiSearchTextFieldProps={{
                            variant: 'filled',
                        }}
                        paginationDisplayMode="pages"
                        positionPagination="bottom"
                        positionGlobalFilter="right"
                        enableColumnActions={false}
                        enableGlobalFilter
                        enableGlobalFilterModes
                        enableColumnFilters={false}
                        enableDensityToggle={false}
                        enableFullScreenToggle={false}
                        onColumnVisibilityChange={setColumnVisibility}
                        muiPaginationProps={{
                            shape: 'rounded',
                            showRowsPerPage: false,
                            showFirstButton: false,
                            showLastButton: false,
                            variant: 'outlined',
                        }}
                        renderTopToolbarCustomActions={() => (
                            <Box flex="1">
                                <Typography variant="h1">{title}</Typography>
                                <Typography variant="h6" className={classes.tableSubtitle}>
                                    {subtitle}
                                </Typography>
                            </Box>
                        )}
                        muiTableBodyRowProps={({ row, staticRowIndex }) => {
                            return {
                                sx: {
                                    cursor: 'pointer',
                                    '&:hover td:after': {
                                        background: 'none',
                                    },
                                    ...(selectedItem && row.original.id === selectedItem?.id
                                        ? {
                                              backgroundColor: theme.colors.darkBlue,
                                              '& td': {
                                                  color: theme.palette.common.white,
                                              },
                                          }
                                        : staticRowIndex % 2 === 1
                                          ? { backgroundColor: theme.palette.common.white }
                                          : { backgroundColor: theme.colors.lightGrey }),
                                },
                                onClick: () => {
                                    setSelectedItem({
                                        id: row.original.id,
                                        type:
                                            row.original.acctType === 'Inventory'
                                                ? 'inventory'
                                                : row.original.accountId
                                                  ? 'transaction'
                                                  : 'lead',
                                    });
                                },
                            };
                        }}
                        onPaginationChange={setPagination}
                        layoutMode="grid"
                        // options={{
                        //   filtering: false,
                        //   paginationType: "stepped",
                        //   showFirstLastPageButtons: false,
                        //   tableLayout: fixedColumnsOptions ? "fixed" : "auto",
                        //   columnsButton: true,
                        //   thirdSortClick: false,
                        //   draggable: false,
                        //   emptyRowsWhenPaging: false,
                        //   searchFieldVariant: "outlined",
                        //   searchFieldStyle: searchFieldStyle(theme),
                        //   headerStyle: headerStyle,
                        // }}
                        // style={tableStyle}
                    ></MaterialTable>
                </Box>
            </SimpleBar>
            {selectedItem && selectedItem.type !== 'inventory' && (
                <Details
                    activityId={selectedItem.id}
                    activityType={selectedItem.type}
                    onCloseDrawer={() => setSelectedItem(null)}
                ></Details>
            )}
            {selectedItem && selectedItem.type === 'inventory' && (
                <InventoryDetails
                    itemId={selectedItem.id}
                    onCloseDrawer={() => setSelectedItem(null)}
                ></InventoryDetails>
            )}
        </Box>
    );
}

export default BaseView;
