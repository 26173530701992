import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    root: {},
    iframe: {
        width: '100%',
        height: '99vh',
        border: 'none',
    },
    integrationIframe: {
        height: '86vh',
        marginTop: '20px',
        [theme.breakpoints.down('md')]: {
            height: '99vh',
        },
    },
}));

export default useStyles;
