import { Autocomplete, Box, Checkbox, Divider, ListItem, Popover, Popper, TextField, Typography } from '@mui/material';
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Button, Search } from '@taycor/ui-library';
import React, { useEffect, useRef, useState } from 'react';
import { ParentVendorDealer } from '../../Helpers/types';
import useStyles from './pvdsAutocomplete.style.ts';

interface PvdsAutocompleteProps {
    pvdIdsArray: ParentVendorDealer[];
    openInitital: boolean;
    anchorEl: Element | null;
    handleSelectedPVDIds: (selected: ParentVendorDealer[]) => void;
    filtering: boolean;
    chipping: boolean;
    selectedCome?: ParentVendorDealer[];
    handleClose: () => void;
    userPVDIds?: ParentVendorDealer[];
    type?: string;
    brandIds?: string[];
}

const PvdsAutocomplete = ({
    pvdIdsArray,
    openInitital,
    anchorEl,
    handleSelectedPVDIds,
    filtering,
    chipping,
    selectedCome,
    handleClose,
    userPVDIds,
    type,
    brandIds,
}: PvdsAutocompleteProps) => {
    const { classes } = useStyles();

    const [pvdIds, setPvdIds] = useState<ParentVendorDealer[]>([]);
    const [selectedPvdIds, setSelectedPvdIds] = useState<ParentVendorDealer[]>([]);
    const selectedPvdIdsRef = useRef(selectedPvdIds);
    const [open, setOpen] = useState<boolean>(false);

    const [checkedPvdIds, setCheckhedPvdIds] = useState<boolean>(false);
    const pvdIdsOptions = pvdIds.sort((a, b) => a.name.localeCompare(b.name));

    useEffect(() => {
        setPvdIds(pvdIdsArray);
    }, [pvdIdsArray]);

    useEffect(() => {
        if (!filtering && !chipping) {
            handleSelectedPVDIds(selectedPvdIds);
        }
    }, [selectedPvdIds]);

    useEffect(() => {
        setOpen(openInitital);
    }, [openInitital]);
    useEffect(() => {
        if (selectedCome) {
            setSelectedPvdIds(selectedCome);
        }
    }, [selectedCome]);

    useEffect(() => {
        if (userPVDIds) {
            const userPVDIdsArray = userPVDIds.map((s) => s.id);
            const filteredPVDIds = pvdIds.filter((x) => userPVDIdsArray.includes(x.id));
            setSelectedPvdIds(filteredPVDIds);
        } else {
            if (brandIds) {
                const filteredPVDIds = selectedPvdIdsRef.current.filter((p) => brandIds?.includes(p.brandId));
                setSelectedPvdIds(filteredPVDIds);
            }
        }
    }, [userPVDIds, pvdIds, brandIds]);

    useEffect(() => {
        selectedPvdIdsRef.current = selectedPvdIds;
    }, [selectedPvdIds]);

    const autocompleteId = () => {
        switch (type) {
            case 'pids':
                return 'pidsaccess';
            case 'vids':
                return 'vidsaccess';
            case 'dids':
                return 'didsaccess';
            case 'rids':
                return 'ridsaccess';
        }
    };

    return (
        <Popover
            onClose={() => handleClose()}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={
                filtering ? { vertical: 'top', horizontal: 'right' } : { vertical: 'bottom', horizontal: 'left' }
            }
        >
            <Box className={classes.pvdsPopoverBox}>
                <Autocomplete
                    open={true}
                    multiple
                    value={selectedPvdIds}
                    id={autocompleteId()}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    classes={{
                        paper: classes.pvdsAutocomplete,
                        option: classes.pvdsMenuOption,
                        listbox: classes.pvdsMenu,
                    }}
                    slots={{
                        popper: (props) => <Popper {...props} placement="bottom-start" />,
                    }}
                    disableCloseOnSelect
                    disablePortal={true}
                    renderTags={() => null}
                    noOptionsText="No results."
                    renderOption={(props, option, { selected }) => {
                        const { key: _, ...rest } = props;
                        return (
                            <ListItem style={{ padding: 0 }} key={rest.id} {...rest}>
                                <Checkbox
                                    color="primary"
                                    onChange={() => {
                                        if (selected) {
                                            const filtered = selectedPvdIds?.filter((item) => item.id !== option.id);
                                            setCheckhedPvdIds(false);
                                            setSelectedPvdIds(filtered);
                                        } else {
                                            setSelectedPvdIds([...selectedPvdIds, option]);
                                        }
                                    }}
                                    checked={
                                        chipping
                                            ? selectedPvdIds.some((element) => element.name === option.name)
                                            : selected || checkedPvdIds
                                    }
                                />
                                <Typography>{option.name}</Typography>
                            </ListItem>
                        );
                    }}
                    options={pvdIdsOptions}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField
                            className={classes.pvdsTextField}
                            {...params}
                            placeholder="Search"
                            variant="outlined"
                            slotProps={{
                                input: {
                                    ...params.InputProps,
                                    startAdornment: <Search />,
                                    endAdornment: <></>,
                                    className: classes.pvdsSearch,
                                },
                            }}
                        />
                    )}
                />

                <Box className={classes.pvdsBox}>
                    <Divider />
                    <Box className={classes.pvdsButtonBox}>
                        <Box className={classes.pvdsSelectClearBox}>
                            <Button
                                variant="text"
                                label="Select all"
                                classes={{ root: classes.pvdsSelectAllButton }}
                                action={() => {
                                    setSelectedPvdIds(pvdIds);
                                    setCheckhedPvdIds(true);
                                }}
                            ></Button>
                            {selectedPvdIds.length > 0 && (
                                <Button
                                    variant="text"
                                    label="Clear selection"
                                    classes={{ root: classes.pvdssClearSelectionButton }}
                                    action={() => {
                                        setSelectedPvdIds([]);
                                        setCheckhedPvdIds(false);
                                    }}
                                ></Button>
                            )}
                        </Box>
                        <Box className={classes.pvdsAddSaveFilterBox}>
                            {filtering && selectedPvdIds.length > 0 && (
                                <Button
                                    style="rectangle"
                                    color="secondary"
                                    variant="contained"
                                    label="Add filter"
                                    classes={{ root: classes.pvdAddFilterButton }}
                                    action={() => {
                                        handleSelectedPVDIds(selectedPvdIds);
                                    }}
                                ></Button>
                            )}
                            {!filtering && chipping && (
                                <Button
                                    style="rectangle"
                                    color="secondary"
                                    variant="contained"
                                    label="Save"
                                    classes={{ root: classes.pvdAddFilterButton }}
                                    action={() => {
                                        handleSelectedPVDIds(selectedPvdIds);
                                    }}
                                ></Button>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Popover>
    );
};

export default PvdsAutocomplete;
