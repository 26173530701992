import React from 'react';
import { getVendors } from '../../Api';
import auth from '../../Services/auth';
import { Data } from '../../Helpers/deal-pipelines';
import { Vendor } from '../../Helpers/types';

interface WithDataProps {}
interface WithDataState {
    data: Data[];
    isLoading: boolean;
    isError: boolean;
    vendors: Vendor[];
    reload: boolean;
}

const withData = <P extends object>(
    InnerComponent: React.ComponentType<P>,
    getData: (success: <Data>(data: Data | Data[]) => void, error: () => void) => void,
) =>
    class WithData extends React.Component<P & WithDataProps, WithDataState> {
        constructor(props: P & WithDataProps) {
            super(props);
            this.state = {
                data: [],
                isLoading: true,
                isError: false,
                vendors: [],
                reload: false,
            };
        }

        loadData() {
            getData(
                (data) => {
                    this.setState({
                        data: data as Data[],
                        isLoading: false,
                        isError: false,
                        reload: false,
                    });
                },
                () => {
                    this.setState({
                        isError: true,
                        isLoading: false,
                        data: [],
                        reload: false,
                    });
                },
            );
        }

        componentDidMount() {
            const isAdmin = auth.isInRole('Admin');
            if (isAdmin) {
                getVendors(
                    (data) => {
                        this.setState({
                            vendors: data as Vendor[],
                        });
                    },
                    () => {
                        this.setState({
                            vendors: [],
                        });
                    },
                );
            }
            this.loadData();
        }

        componentDidUpdate() {
            if (this.state.reload) {
                this.loadData();
            }
        }

        render() {
            return (
                <InnerComponent
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    vendors={this.state.vendors}
                    onReload={() => this.setState({ reload: true, isLoading: true })}
                    {...(this.props as P)}
                />
            );
        }
    };

export default withData;
