import { formField } from '../../Theme';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles()((theme: Theme) => {
    return {
        root: {
            display: 'flex',
            flexGrow: 1,
        },
        leftDrawer: {
            width: theme.layout.leftDrawerWidth,
        },
        leftDrawerPaper: {
            width: theme.layout.leftDrawerWidth,
            backgroundColor: theme.colors.lightGrey,
            borderRight: 'none',
            overflowX: 'hidden',
            display: 'flex',
            flexGrow: 1,
        },
        leftDrawerOpen: {
            width: theme.layout.leftDrawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        leftDrawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.layout.menuWidth,
        },
        drawerToggle: {
            zIndex: 1200,
            position: 'fixed',
            top: 28,
            backgroundColor: theme.palette.common.white,
            color: theme.colors.darkBlue,
            borderStyle: 'solid',
            height: 33,
            width: 33,
            borderColor: theme.colors.lightGrey,
            borderWidth: 3,
            cursor: 'pointer',
        },
        drawerToggleOpen: {
            left: 235,
            transition: theme.transitions.create('left', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerToggleClose: {
            left: 84,
            transition: theme.transitions.create('left', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            '&:hover': {
                backgroundColor: theme.colors.darkBlue,
                color: theme.palette.common.white,
            },
        },
        topMenuBar: {
            zIndex: 1199,
            position: 'fixed',
            backgroundColor: theme.colors.darkBlue,
            color: theme.palette.common.white,
            height: 43,
            width: '100vw',
        },
        drawerToggleMobile: {
            zIndex: 1200,
            position: 'fixed',
            color: theme.palette.common.white,
            backgroundColor: theme.colors.darkBlue,
            height: 39,
            width: 49,
        },
        hidden: {
            display: 'none',
        },
        notVisible: {
            visibility: 'hidden',
        },
        drawerContent: {
            backgroundColor: theme.colors.darkBlue,
            display: 'flex',
            flexGrow: 1,
        },
        drawerContentMidpoint: {
            backgroundColor: '#6f4d9e',
            display: 'flex',
            flexGrow: 1,
        },
        drawerMenuContainerExpanded: {
            marginLeft: 10,
            '& .MuiGrid-item': {
                width: theme.layout.leftDrawerWidth - 10,
            },
            background: theme.colors.lightGrey,
            transition: theme.transitions.create('background-color', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerMenuContainerCollapsed: {
            marginLeft: 0,
            '& .MuiGrid-item': {
                width: theme.layout.leftDrawerWidth,
            },
            background: theme.colors.darkBlue,
            transition: theme.transitions.create('background-color', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerMenuContainerCollapsedMidpoint: {
            marginLeft: 0,
            '& .MuiGrid-item': {
                width: theme.layout.leftDrawerWidth,
            },
            background: '#6f4d9e',
            transition: theme.transitions.create('background-color', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerLogo: {
            paddingTop: 24,
            paddingBottom: 6,
            marginLeft: 24,
            width: 180,
            '& .MuiAvatar-root': {
                width: 180,
                '& img': {
                    width: 180,
                    height: 28,
                },
            },
        },
        drawerLogoMidpoint: {
            paddingTop: 24,
            paddingBottom: 6,
            marginLeft: 24,
            width: 180,
            '& .MuiAvatar-root': {
                width: 180,
                '& img': {
                    width: 180,
                    objectFit: 'fill',
                    height: 35,
                },
            },
        },
        drawerLogoCollapsed: {
            width: 34,
            marginLeft: 26,
            '& .MuiAvatar-root': {
                width: 50,
                height: 43.5,
                '& img': {
                    width: 50,
                    height: 43.5,
                },
            },
        },
        drawerMenuItem: {
            paddingRight: 0,
            height: 42,
        },
        drawerMenuItemExpanded: {
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: theme.spacing(6),
            '&:hover': {
                backgroundColor: theme.colors.hoverBackground,
                '& svg': {
                    color: theme.palette.secondary.main,
                },
            },
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerMenuItemCollapsed: {
            paddingLeft: 38,
            '&:hover': {
                backgroundColor: theme.colors.darkBlue,
                '& svg': {
                    color: theme.palette.common.white,
                },
            },
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        drawerMenuItemIconExpanded: {
            color: theme.colors.menuIconDefault,
            minWidth: theme.spacing(11),
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerMenuItemIconCollapsed: {
            color: theme.palette.common.white,
            minWidth: 66,
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            '&.MuiListItemIcon-root': {
                minWidth: 66,
            },
        },
        drawerMenuItemActive: {
            '& span.MuiTypography-h6': {
                fontWeight: 500,
            },
            '& svg': {
                color: theme.palette.secondary.main,
            },
            '& .MuiBox-root': {
                backgroundColor: theme.palette.secondary.main,
            },
            '&:hover': {
                '& svg': {
                    color: theme.colors.darkBlue,
                },
            },
        },
        drawerMenuItemActiveCollapsed: {
            '&:hover': {
                '& svg': {
                    color: theme.palette.common.white,
                },
            },
        },
        drawerMenuItemHeader: {
            '&:hover': {
                cursor: 'default',
            },
        },
        drawerMenuItemHeaderAdmin: {
            [theme.breakpoints.down('lg')]: {
                display: 'none',
            },
        },
        drawerMenuItemHeaderExpanded: {
            '&:hover': {
                backgroundColor: theme.colors.lightGrey,
            },
            '& h5': {
                marginLeft: theme.spacing(13),
                transition: theme.transitions.create('margin-left', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
        },
        drawerMenuItemHeaderCollapsed: {
            '&:hover': {
                backgroundColor: theme.colors.darkBlue,
            },
            '& h5': {
                marginLeft: 84,
                transition: theme.transitions.create('margin-left', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            },
        },
        drawerMenuItemEnd: {
            position: 'absolute',
            height: 51,
            width: 3,
        },
        drawerMenuItemEndOpen: {
            left: theme.layout.leftDrawerWidth - 13,
            transition: theme.transitions.create('left', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerMenuItemEndClose: {
            left: theme.layout.menuWidth - 3,
            transition: theme.transitions.create('left', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        dividerContainer: {
            height: 1,
            marginLeft: theme.spacing(14),
            marginRight: theme.spacing(14),
            marginTop: 0,
            marginBottom: 10,
            overflow: 'visible',
            '& div': {
                height: 1,
                marginLeft: theme.spacing(3),
                marginRight: theme.spacing(3),
                '& div': {
                    height: 1,
                    marginLeft: theme.spacing(3),
                    marginRight: theme.spacing(3),
                },
            },
        },
        divider: {
            position: 'absolute',
            left: theme.spacing(9),
            marginTop: -10,
            width: 188,
            backgroundColor: theme.palette.common.white,
        },
        dividerExpanded: {
            left: theme.spacing(9),
        },
        dividerCollapsed: {
            left: 11,
            width: 78,
        },
        dividerCollapsedFull: {
            left: 0,
            width: 100,
        },
        userAvatarContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
        },
        userAvatarContent: {
            marginBottom: theme.spacing(4),
            width: theme.layout.leftDrawerWidth - 10,
        },
        userAvatarBorder: {
            position: 'absolute',
            borderColor: theme.colors.lightBlue,
            border: '4px solid',
            width: 54,
            height: 54,
            zIndex: 1,
            borderRadius: 27,
        },
        userAvatarIcon: {
            float: 'left',
            marginLeft: 14,
            height: 54,
            width: 54,
            backgroundColor: theme.palette.common.white,
            color: theme.colors.lightBlue,
            '& svg': {
                width: 58,
                height: 58,
                color: theme.colors.darkGrey,
            },
        },
        userAvatarName: {
            float: 'left',
            marginLeft: 10,
            marginTop: theme.spacing(2),
        },
        userAvatarDots: {
            height: 38,
            width: 38,
            float: 'right',
            marginTop: 8,
            '& svg': {
                color: theme.colors.mediumGrey,
                height: theme.spacing(9),
                width: theme.spacing(9),
            },
        },
        userAvatarDotsCollapsed: {
            float: 'left',
            height: 34,
            width: 34,
            marginTop: 10,
        },
        contentContainer: {
            overflowX: 'hidden',
            display: 'flex',
            flexGrow: 1,
            width: `calc(100vw - ${theme.layout.leftDrawerWidth}px - 250px)`,
        },
        contentContainerExpanded: {
            backgroundColor: theme.colors.lightGrey,
            transition: theme.transitions.create('background-color', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        contentContainerCollapsed: {
            backgroundColor: theme.colors.darkBlue,
            transition: theme.transitions.create('background-color', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        content: {
            borderTopLeftRadius: 22,
            backgroundColor: theme.palette.common.white,
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                width: `100vw`,
            },
        },
        notificationsToggle: {
            paddingLeft: 0,
        },
        centerAlign: {
            display: 'flex',
            justifyContent: 'center',
        },
        copyApp: {
            textTransform: 'initial',
            fontSize: 13,
            marginTop: 8,
            '& svg': {
                width: 12,
                height: 12,
            },
        },
        newAppButton: {
            marginTop: theme.spacing(5),
            color: theme.palette.common.white,
            textTransform: 'capitalize',
            width: 180,
        },
        newAppIcon: {
            marginTop: theme.spacing(5),
            left: 38,
            color: theme.palette.common.white,
            height: 24,
            width: 24,
        },
        newAppContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            '& input': {
                marginTop: 8,
                width: 186,
                cursor: 'pointer',
                ...formField(theme),
            },
        },
        newAppCollapsed: {
            display: 'flex',
            flexDirection: 'column',
        },
        collapsedAppIcon: {
            marginTop: theme.spacing(5),
            left: 38,
            height: 24,
            width: 24,
            '& svg': {
                color: theme.palette.common.white,
            },
        },
    };
});

export const menuItemTooltipStyle = (theme: Theme) => ({
    backgroundColor: theme.colors.darkBlue,
    color: theme.palette.common.white,
    height: 41,
    marginLeft: '38px !important',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '22px',
    letterSpacing: '0.28px',
});

export default useStyles;
