import axios, { AxiosResponse } from 'axios';
import config from '../config';
import logger from '../Helpers/logger';

const postWithFiles = <D extends Record<string, unknown>, T>(
    url: string,
    data: D,
    files: File[],
    fakeData?: AxiosResponse<T>,
) => {
    const endpoint = buildEndpointUrl(url);
    if (config.uploadApi.fakeIt) {
        return new Promise((resolve) => {
            setTimeout(() => resolve(fakeData || { succes: true }), 500);
        });
    } else {
        const formData = new FormData();
        Object.keys(data).map((key) =>
            Array.isArray(data[key])
                ? (data[key] as string[] | Blob[]).map((value, index) => formData.append(`${key}[${index}]`, value))
                : formData.append(key, data[key] === null ? '' : `${data[key]}`),
        );
        Object.keys(files).map((key: string) => formData.append(key, files[parseInt(key)]));

        return axios
            .post(endpoint, formData, {
                headers: getHeaders(config.uploadApi.uploadDocumentsCode || ''),
            })
            .then((response) => response.data)
            .catch((err) => {
                logger.logError(err);
                throw err;
            });
    }
};

const buildEndpointUrl = (url: string) => `${config.uploadApi.url}${url}`;
const getHeaders = (key: string) => ({ 'x-functions-key': key });

export { postWithFiles };
