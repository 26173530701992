import { Avatar, Box, Theme, Tooltip, Typography } from '@mui/material';
import { avatarStyle, avatarTextStyle, chipStyle } from './columns.style';

interface RenderChipProps {
    value: string;
    theme: Theme;
    valueToStyle: (theme: Theme, value: string) => React.CSSProperties;
    getDisplayStatus: (status: string) => string;
}
export const renderChip = ({ value, theme, valueToStyle, getDisplayStatus }: RenderChipProps) => {
    const displayStatus = getDisplayStatus(value);
    return (
        <Box style={chipStyle(valueToStyle(theme, displayStatus))}>
            <Typography variant="body2">{displayStatus}</Typography>
        </Box>
    );
};

export const renderAvatar = (value: string) => (
    <Box style={avatarStyle}>
        <Tooltip title={value}>
            <Avatar>{value ? value.replace(/[^A-Z{2}]/g, '') : 'N/A'}</Avatar>
        </Tooltip>
        <Typography variant="body1" style={avatarTextStyle}>
            {value ? value.substring(0, value.indexOf(' ')) : 'N/A'}
        </Typography>
    </Box>
);
