import React from 'react';
import {
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Box,
    useTheme,
    Theme,
} from '@mui/material';
import useStyles, { connectorHeight } from './milestones.style';
import { getMilestones } from '../../../../Helpers/deal-pipelines';
import { ActivityType } from '../../../../Helpers/types';

const getIndicatorStyle = (theme: Theme, status: string) => {
    const statusNameNormalized = status.split(' ').join('').replace('-', '');
    const color = theme.rightDrawer.statusStyles[statusNameNormalized] || theme.rightDrawer.statusStyles.default;
    return { backgroundColor: color };
};
export interface Milestone {
    [status: string]: string | null;
}
interface MilestonesProps {
    milestones: Milestone;
    activityType: ActivityType;
}

const Milestones = (props: MilestonesProps) => {
    const theme = useTheme();
    const { classes } = useStyles();

    const milestones = getMilestones(props.milestones, props.activityType === 'lead' ? 'lead' : 'transaction');
    const connectorStyle = {
        height: connectorHeight * ((milestones.length || 0) - 1),
    };

    return (
        <>
            <Box className={classes.title}>
                <Typography variant="h4">Milestones</Typography>
            </Box>
            <TableContainer component={Paper} className={classes.table}>
                <Table>
                    <TableBody>
                        {milestones.map((milestone, index: number) => (
                            <TableRow key={index}>
                                <TableCell className={classes.tableIndicatorCell}>
                                    <Box
                                        className={classes.colorIndicator}
                                        style={getIndicatorStyle(theme, milestone.status)}
                                    ></Box>
                                </TableCell>
                                <TableCell className={classes.tableStatusCell}>
                                    <Typography variant="h6">{milestone.status}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableDateCell}>
                                    <Typography variant="h6">{milestone.date?.toLocaleDateString() || ''}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableTimeCell}>
                                    <Typography variant="h6">
                                        {milestone.date?.toLocaleTimeString([], {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        }) || ''}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box className={classes.connector} style={connectorStyle}></Box>
        </>
    );
};

export default Milestones;
