import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
    },
    header: {
        height: '6vh',
        backgroundColor: theme.colors.lightGrey,
        borderBottom: '1px solid ' + theme.palette.grey[200],
    },
    content: {
        display: 'flex',
    },
    leftHalf: {
        flex: 0.5,
    },
    leftHalfBackground: {
        backgroundImage: 'url(/left-banner-graphic.svg)',
        backgroundSize: 'cover',
        height: '100vh',
        width: '50vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down(1005)]: {
            width: 'auto',
        },
        [theme.breakpoints.down(865)]: {
            height: 'auto',
            width: '100vw',
        },
    },
    leftHalfBackgroundMidpoint: {
        backgroundImage: 'url(/midpoint_bg.png)',
        backgroundColor: '#6f4d9e',
        backgroundSize: 'cover',
        height: '100vh',
        width: '50vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down(1005)]: {
            width: 'auto',
        },
        [theme.breakpoints.down(865)]: {
            height: 'auto',
            width: '100vw',
        },
    },
    logo: {
        height: '52px',
        width: '326px',
        [theme.breakpoints.down(865)]: {
            height: 100,
            width: '100vw',
            '& img': {
                height: 50,
                width: 'auto',
            },
        },
    },
    logoMidpoint: {
        height: 254,
    },
    rightHalf: {
        flex: 0.5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export default useStyles;
