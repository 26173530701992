import React from 'react';
import { AuthConsumer } from '../Auth';
import { Role } from '../../Services/auth';

interface PermissionProps {
    role: Role;
    children: React.ReactNode;
}

const Permission = (props: PermissionProps) => {
    return (
        <AuthConsumer>
            {({ isInRole }) => {
                return isInRole(props.role) ? props.children : <></>;
            }}
        </AuthConsumer>
    );
};

export default Permission;
