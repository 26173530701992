import { Theme } from '@mui/material/styles';
import { MRT_ColumnDef } from 'material-react-table';
import { Data } from '../../../Helpers/deal-pipelines';
import { estFundDateToDealerNotes, internalToDisplayStatus, statusToStyle } from '../../../Helpers/inventory-tracker';
import { renderChip } from '../../DealPipeline/common/columns';
import moment from 'moment';
import { compareStrings } from '../../../Helpers/utils';

const getColumns = (theme: Theme) => {
    const columns: Array<MRT_ColumnDef<Data>> = [
        {
            accessorKey: 'compName',
            header: 'Model Number',
            enableHiding: false,
            size: 110,
        },
        {
            accessorKey: 'acctInvDesc',
            header: 'Serial Number',
            enableHiding: false,
            size: 180,
        },
        {
            header: 'Current Status',
            accessorFn: (row) => internalToDisplayStatus(row.acctStatus),
            Cell: ({ row }) =>
                renderChip({
                    value: row.original.acctStatus,
                    theme,
                    valueToStyle: statusToStyle,
                    getDisplayStatus: internalToDisplayStatus,
                }),
            filterFn: (row, id, filterValue) => {
                const rowData: Data = row.getValue(id);
                const value = internalToDisplayStatus(rowData.acctStatus) || '';
                return value.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0;
            },
            sortingFn: (rowA, rowB) => {
                return compareStrings(
                    internalToDisplayStatus(rowA.original.acctStatus),
                    internalToDisplayStatus(rowB.original.acctStatus),
                );
            },
            size: 180,
        },
        {
            accessorKey: 'acctCost',
            header: 'Unit Price',
            Cell: ({ row }) => {
                return row.original.acctCost
                    ? new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                      }).format(parseInt(row.original.acctCost))
                    : null;
            },
            // cellStyle: centerAlignCellStyle,
            // headerStyle: centerAlignCellStyle,
            size: 110,
        },
        {
            accessorKey: 'vendorName',
            header: 'Dealer ID',
            size: 220,
        },
        {
            accessorKey: 'lastUpdated',
            header: 'Last Updated',
            Cell: ({ row }) => {
                return row.original.lastUpdated
                    ? `${moment(row.original.lastUpdated).format('DD/MM/YYYY hh:mm:ss')}`
                    : null;
            },
            size: 100,
        },
        {
            header: 'Dealer Notes',
            accessorFn: (row) => estFundDateToDealerNotes(row.acctOutlook),
            Cell: ({ row }) => estFundDateToDealerNotes(row.original.acctOutlook),
            filterFn: (row, id, filterValue) => {
                const rowData: Data = row.getValue(id);
                const value = estFundDateToDealerNotes(rowData.acctOutlook) || '';
                return value.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0;
            },
            sortingFn: (rowA, rowB) => {
                return compareStrings(
                    internalToDisplayStatus(rowA.original.acctOutlook),
                    internalToDisplayStatus(rowB.original.acctOutlook),
                );
            },
            size: 120,
        },
        {
            accessorKey: 'acctFundDate',
            header: 'Release or Sale Date',
            Cell: ({ row }) => {
                return row.original.acctFundDate ? `${moment(row.original.acctFundDate).format('DD/MM/YYYY')}` : null;
            },
            size: 90,
        },
    ];
    return columns;
};
const columnsPVD: Array<MRT_ColumnDef<Data>> = [];

export { columnsPVD, getColumns };
