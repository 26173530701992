import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import { FieldProps, getIn } from 'formik';
export interface TextFieldProps extends FieldProps, Omit<MuiTextFieldProps, 'name' | 'value' | 'error'> {}

export const fieldToTextField = ({
    disabled,
    field: defaultField,
    form,
    onBlur,
    helperText,
    ...props
}: TextFieldProps) => {
    const { onBlur: fieldOnBlur, ...field } = defaultField;
    const { isSubmitting, touched, errors } = form;

    const fieldError = getIn(errors, field.name);
    const showError = getIn(touched, field.name) && !!fieldError;

    return {
        error: showError,
        helperText: showError ? fieldError : helperText,
        disabled: disabled ?? isSubmitting,
        onBlur:
            onBlur ??
            function (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) {
                fieldOnBlur(e ?? field.name);
            },
        ...field,
        ...props,
    };
};

export const FormikMaterialUITextField = ({ children, ...props }: TextFieldProps) => {
    return <MuiTextField {...fieldToTextField(props)}>{children}</MuiTextField>;
};
