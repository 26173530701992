import { useEffect } from 'react';
import { useBrandedPortal } from '../../Helpers/hooks/useBrandedPortal';

const FaviconManager = () => {
    const { isBrandedPortal, favicon } = useBrandedPortal();

    useEffect(() => {
        if (isBrandedPortal && favicon) {
            const faviconLink = document.createElement('link');
            faviconLink.rel = 'icon';
            faviconLink.type = 'image.png';
            faviconLink.href = favicon;
            document.head.appendChild(faviconLink);
            return () => {
                document.head.removeChild(faviconLink);
            };
        }
    }, []);
    return null;
};
export default FaviconManager;
