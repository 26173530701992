export default {
    api: {
        url: 'https://api-portal-tfg-pre-prod.azurewebsites.net/api/v1',
    },
    ai: {
        key: 'c3992e32-4191-4592-95b8-9533eae87c3b',
    },
    uploadApi: {
        url: 'https://taycor-doc-upload-staging.azurewebsites.net/api/v1',
    },
};
