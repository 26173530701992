import { Theme } from '@mui/material/styles';

const styles = (theme: Theme) => ({
    expandedMenu: {
        marginLeft: 0,
        transition: theme.transitions.create('margin-left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    collapsedMenu: {
        marginLeft: 0,
        transition: theme.transitions.create('margin-left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    headerText: {
        marginTop: theme.spacing(10),
        marginLeft: 50,
        [theme.breakpoints.down(breakpoints.first)]: {
            marginLeft: 20,
        },
    },
    headerSubtitle: {
        marginTop: theme.spacing(2),
        marginLeft: 50,
        [theme.breakpoints.down(breakpoints.first)]: {
            marginLeft: 20,
        },
    },
    tiles: {
        marginTop: 85,
        margin: theme.spacing(3),
        [theme.breakpoints.down(breakpoints.first)]: {
            margin: 0,
        },
    },
    tilesIntegration: {
        margin: 0,
    },
    tilesGroup: {
        justifyContent: 'center',
        [theme.breakpoints.down(breakpoints.first)]: {
            justifyContent: 'center',
        },
    },
    tileContainer: {
        textAlign: 'center' as unknown as 'center',
        margin: theme.spacing(5, 9),
        [theme.breakpoints.down(breakpoints.first)]: {
            marginLeft: 20,
        },
    },
    tile: {
        width: 338,
        height: 267,
        color: '#8f8f8f',
        borderRadius: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column' as unknown as 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: theme.palette.common.white,
        borderBottomWidth: theme.spacing(2),
        borderBottomStyle: 'solid' as unknown as 'solid',
        [theme.breakpoints.down(breakpoints.first)]: {
            width: 300,
        },
        [theme.breakpoints.down(breakpoints.second)]: {
            width: 250,
        },
        [theme.breakpoints.down(breakpoints.third)]: {
            width: 200,
        },
    },
    tileAvatar: {
        width: 338,
        height: 190,
        paddingBottom: 4,
        paddingLeft: 81.5,
        paddingRight: 81.5,
        opacity: 0.5,
    },
    tileAvatarTotalLeads: {
        paddingTop: 22.5,
        '& img': {
            width: 174,
            height: 'auto',
        },
    },
    tileAvatarActiveLeads: {
        paddingTop: 0,
        paddingBottom: 20,
        '& img': {
            width: 170,
            height: 'auto',
        },
    },
    tileAvatarActiveTransactions: {
        paddingTop: 34,
        '& img': {
            width: 180,
            height: 'auto',
        },
    },
    tileAvatarFundedTransactions: {
        paddingTop: 19,
        '& img': {
            width: 170,
            height: 'auto',
        },
    },
    tileAvatarLast30DaysLeads: {
        paddingTop: 23,
        '& img': {
            width: 227,
            height: 'auto',
        },
    },
    tileAvatarYtdLeads: {
        paddingTop: 23,
        '& img': {
            width: 180,
            height: 'auto',
        },
    },
    tileAvatarAvgTransactionSize: {
        paddingTop: 27.5,
        '& img': {
            width: 160,
            height: 'auto',
        },
    },
    tileAvatarFundedVolume: {
        paddingTop: 27.5,
        '& img': {
            width: 180,
            height: 'auto',
        },
    },
    tileText: {
        display: 'flex',
        flexDirection: 'column' as unknown as 'column',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: 250,
        [theme.breakpoints.down(breakpoints.third)]: {
            '& h2': {
                fontSize: 18,
            },
        },
    },
    tileNumber: {
        marginTop: 6,
        marginBottom: 6,
    },
    tileNumberLight: {
        color: theme.colors.lightBlue,
        fontWeight: 500,
    },
    tileNumberDark: {
        color: theme.colors.darkBlue,
        fontWeight: 500,
    },
    tileBorderLight: {
        borderBottomColor: theme.colors.lightBlue,
    },
    tileBorderDark: {
        borderBottomColor: theme.colors.darkBlue,
    },
    tileClickable: {
        '& .MuiAvatar-root:hover': {
            opacity: 1,
        },
    },
    link: {
        textDecoration: 'none',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none',
        },
        color: 'unset',
    },
    progress: {
        color: theme.colors.darkBlue,
    },
});

const breakpoints = {
    first: 650,
    second: 432,
    third: 382,
};

export default styles;
