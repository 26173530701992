import { formField } from '../../Theme';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        marginTop: 100,
        marginLeft: theme.spacing(12),
        marginRight: theme.spacing(14),
        width: `calc(100vw - ${theme.layout.leftDrawerWidth}px - 104px)`,
        [theme.breakpoints.down('md')]: {
            width: `calc(100vw - 102px)`,
        },
    },
    gridContainer: {
        marginTop: 20,
        boxShadow: '0px 6px 20px 0 rgba(193, 207, 217, 0.2)',
        padding: theme.spacing(4),
    },
    grid: {
        marginTop: 10,
    },
    button: {
        textTransform: 'capitalize',
    },
    primaryButton: {
        marginTop: theme.spacing(2),
        color: theme.palette.common.white,
    },
    fieldLabel: {
        width: 112,
    },
    field: {
        width: 330,
        ...formField(theme),
    },
    alert: {
        marginTop: theme.spacing(4),
        width: 545,
    },
    visible: {
        display: 'block',
    },
    hidden: {
        display: 'none',
    },
}));

export default useStyles;
