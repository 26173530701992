import { Theme } from '@mui/material/styles';
import { MRT_ColumnDef } from 'material-react-table';
// import { centerAlignCellStyle } from "../common/columns.style";
import moment from 'moment';
import {
    Data,
    getPartShareDisplayValue,
    internalToDisplayStatus,
    statusToStyle,
} from '../../../Helpers/deal-pipelines';
import { compareStrings } from '../../../Helpers/utils';
import { renderAvatar, renderChip } from '../common/columns';

const getColumns = (theme: Theme) => {
    const columns: Array<MRT_ColumnDef<Data>> = [
        {
            accessorKey: 'accountId',
            header: 'Account ID',
            enableHiding: false,
            size: 100,
        },
        {
            accessorKey: 'compName',
            header: 'Company Name',
            enableHiding: false,
            size: 270,
        },
        {
            accessorKey: 'leadDate',
            header: 'Created',
            Cell: ({ row }) => {
                return row.original.leadDate ? `${moment(row.original.leadDate).format('DD/MM/YYYY')}` : null;
            },
            size: 95,
        },
        {
            accessorKey: 'leadType',
            header: 'Comp Type',
            size: 90,
        },
        {
            header: 'Current Status',
            accessorFn: (row) => internalToDisplayStatus(row.acctStatus, 'transaction'),
            Cell: ({ row }) =>
                renderChip({
                    value: row.original.acctStatus,
                    theme,
                    valueToStyle: statusToStyle,
                    getDisplayStatus: (s: string) => internalToDisplayStatus(s, 'transaction'),
                }),
            filterFn: (row, id, filterValue) => {
                const rowData: Data = row.getValue(id);
                const value = internalToDisplayStatus(rowData.acctStatus, 'transaction') || '';
                return value.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0;
            },
            sortingFn: (rowA, rowB) => {
                return compareStrings(
                    internalToDisplayStatus(rowA.original.acctStatus, 'transaction'),
                    internalToDisplayStatus(rowB.original.acctStatus, 'transaction'),
                );
            },
            size: 180,
        },
        {
            accessorKey: 'repName',
            header: 'Finance Manager',
            Cell: ({ row }) => renderAvatar(row.original.repName),
            size: 115,
            // cellStyle: { overflowWrap: "break-word" },
        },
        {
            accessorKey: 'acctType',
            header: 'Product',
            size: 100,
        },
        {
            accessorKey: 'acctCost',
            header: 'Loan Amount',
            Cell: ({ row }) => {
                return row.original.acctCost
                    ? new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                      }).format(parseInt(row.original.acctCost))
                    : null;
            },
            // cellStyle: centerAlignCellStyle,
            // headerStyle: centerAlignCellStyle,
            size: 110,
        },
        {
            accessorKey: 'oid',
            header: 'OID',
            size: 110,
            // cellStyle: { overflowWrap: "break-word" },
        },
        {
            accessorKey: 'bid',
            header: 'BID',
            size: 110,
            // cellStyle: { overflowWrap: "break-word" },
        },
        {
            accessorKey: 'pid',
            header: 'PID',
            size: 110,
            // cellStyle: { overflowWrap: "break-word" },
        },
        {
            accessorKey: 'vendorName',
            header: 'Lead Source (VID)',
            size: 130,
            // cellStyle: { overflowWrap: "break-word" },
        },
        {
            accessorKey: 'did',
            header: 'DID',
            size: 110,
            // cellStyle: { overflowWrap: "break-word" },
        },
        {
            accessorKey: 'rid',
            header: 'RID',
            size: 110,
            // cellStyle: { overflowWrap: "break-word" },
        },
        {
            accessorKey: 'eid',
            header: 'EID',
            size: 110,
            // cellStyle: { overflowWrap: "break-word" },
        },
        {
            accessorKey: 'lastTouch',
            header: 'Last Touch',
            Cell: ({ row }) => {
                return row.original.lastTouch ? `${moment(row.original.lastTouch).format('DD/MM/YYYY')}` : null;
            },
            size: 90,
        },
        {
            accessorKey: 'acctFundDate',
            header: 'Date Funded',
            Cell: ({ row }) => {
                return row.original.acctFundDate ? `${moment(row.original.acctFundDate).format('DD/MM/YYYY')}` : null;
            },
            size: 90,
        },
        {
            header: 'Part. Share',
            accessorKey: 'acctAmtPayable',
            Cell: ({ row }) => getPartShareDisplayValue(row.original),
            filterFn: (row, id, filterValue) => {
                const rowData: Data = row.getValue(id);
                return getPartShareDisplayValue(rowData).indexOf(filterValue.toLowerCase()) >= 0;
            },
            sortingFn: (rowA, rowB) => {
                return compareStrings(getPartShareDisplayValue(rowA.original), getPartShareDisplayValue(rowB.original));
            },
            size: 85,
        },
        {
            accessorKey: 'vendorSalesRep',
            header: 'Vendor Sales Rep',
            size: 110,
        },
        {
            accessorKey: 'lastUpdated',
            header: 'Last Updated',
            Cell: ({ row }) => {
                return row.original.lastUpdated
                    ? `${moment(row.original.lastUpdated).format('DD/MM/YYYY hh:mm:ss')}`
                    : null;
            },
            enableGlobalFilter: false, // Not searchable
            size: 100,
        },
    ];
    return columns;
};

const columnsPVD: Array<MRT_ColumnDef<Data>> = [
    {
        accessorKey: 'pid',
        header: 'PID',
        size: 110,
    },
    {
        accessorKey: 'did',
        header: 'DID',
        size: 110,
    },
    {
        accessorKey: 'rid',
        header: 'RID',
        size: 110,
    },
    {
        accessorKey: 'eid',
        header: 'EID',
        size: 110,
    },
];
export { columnsPVD, getColumns };
