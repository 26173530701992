import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import config from '../config';

export const useGaTracking = (trackingId: string) => {
    const { listen } = useHistory();

    useEffect(() => {
        const unlisten = listen((location: Location) => {
            if (!config.ga.enabled) return;
            if (!window.gtag) return;
            if (!trackingId) return;
            window.gtag('config', trackingId, { page_path: location.pathname });
        });

        return unlisten;
    }, [trackingId, listen]);
};
