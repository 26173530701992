import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    pvdsAutocomplete: {
        width: 424,
    },
    pvdsGrid: {
        marginTop: theme.spacing(8),
    },
    limitTag: {
        backgroundColor: '#EFF1F5',
        height: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 30,
    },
}));

export default useStyles;
