import { Theme } from '@mui/material/styles';
import { MRT_ColumnDef } from 'material-react-table';
import moment from 'moment';
import {
    Data,
    getCompanyIdDisplayValue,
    getFinanceManager,
    internalToDisplayStatus,
    statusToStyle,
} from '../../../Helpers/deal-pipelines';
import { compareStrings } from '../../../Helpers/utils';
import { renderAvatar, renderChip } from '../common/columns';

const getColumns = (theme: Theme) => {
    const columns: Array<MRT_ColumnDef<Data>> = [
        {
            header: 'Comp ID',
            accessorFn: (row) => getCompanyIdDisplayValue(row),
            Cell: ({ row }) => getCompanyIdDisplayValue(row.original),
            sortingFn: (rowA, rowB) => {
                return compareStrings(getCompanyIdDisplayValue(rowA.original), getCompanyIdDisplayValue(rowB.original));
            },
            filterFn: (row, id, filterValue) => {
                const rowData: Data = row.getValue(id);
                return getCompanyIdDisplayValue(rowData).indexOf(filterValue.toLowerCase()) >= 0;
            },
            enableHiding: false,
            size: 250,
        },
        {
            accessorKey: 'compName',
            header: 'Company Name',
            enableHiding: false,
            size: 270,
        },
        {
            accessorKey: 'leadDate',
            header: 'Created',
            Cell: ({ row }) => {
                return row.original.leadDate ? `${moment(row.original.leadDate).format('DD/MM/YYYY')}` : null;
            },
            size: 95,
        },
        {
            accessorKey: 'primaryContact',
            header: 'Contact Name',
            size: 110,
        },
        {
            accessorKey: 'leadType',
            header: 'Comp Type',
            size: 90,
        },
        {
            header: 'Current Status',
            accessorFn: (row) => internalToDisplayStatus(row.leadStatus, 'lead'),
            Cell: ({ row }) =>
                renderChip({
                    value: row.original.leadStatus,
                    theme,
                    valueToStyle: statusToStyle,
                    getDisplayStatus: (s: string) => internalToDisplayStatus(s, 'lead'),
                }),
            filterFn: (row, id, filterValue) => {
                const rowData: Data = row.getValue(id);
                const value = internalToDisplayStatus(rowData.leadStatus, 'lead') || '';
                return value.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0;
            },
            sortingFn: (rowA, rowB) => {
                return compareStrings(
                    internalToDisplayStatus(rowA.original.leadStatus, 'lead'),
                    internalToDisplayStatus(rowB.original.leadStatus, 'lead'),
                );
            },
            size: 190,
        },
        {
            header: 'Finance Manager',
            accessorFn: (row) => getFinanceManager(row),
            Cell: ({ row }) => {
                return renderAvatar(getFinanceManager(row.original));
            },
            filterFn: (row, id, filterValue) => {
                const rowData: Data = row.getValue(id);
                const value = getFinanceManager(rowData) || '';
                return value.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0;
            },
            sortingFn: (rowA, rowB) => {
                return compareStrings(getFinanceManager(rowA.original), getFinanceManager(rowB.original));
            },
            size: 120,
            // cellStyle: { overflowWrap: "break-word" },
        },
        {
            accessorKey: 'oid',
            header: 'OID',
            size: 110,
            // cellStyle: { overflowWrap: "break-word" },
        },
        {
            accessorKey: 'bid',
            header: 'BID',
            size: 110,
            // cellStyle: { overflowWrap: "break-word" },
        },
        {
            accessorKey: 'pid',
            header: 'PID',
            size: 110,
            // cellStyle: { overflowWrap: "break-word" },
        },
        {
            accessorKey: 'vendorName',
            header: 'Lead Source (VID)',
            size: 130,
            // cellStyle: { overflowWrap: "break-word" },
        },
        {
            accessorKey: 'did',
            header: 'DID',
            size: 110,
            // cellStyle: { overflowWrap: "break-word" },
        },
        {
            accessorKey: 'rid',
            header: 'RID',
            size: 110,
            // cellStyle: { overflowWrap: "break-word" },
        },
        {
            accessorKey: 'eid',
            header: 'EID',
            size: 110,
            // cellStyle: { overflowWrap: "break-word" },
        },
        {
            accessorKey: 'lastTouch',
            header: 'Last Touch',
            Cell: ({ row }) => {
                return row.original.lastTouch ? `${moment(row.original.lastTouch).format('DD/MM/YYYY')}` : null;
            },
            size: 90,
        },
        {
            accessorKey: 'nextTouch',
            header: 'Next Touch',
            Cell: ({ row }) => {
                return row.original.nextTouch ? `${moment(row.original.nextTouch).format('DD/MM/YYYY')}` : null;
            },
            size: 90,
        },
        {
            accessorKey: 'vendorSalesRep',
            header: 'Vendor Sales Rep',
            size: 110,
        },
        {
            accessorKey: 'lastUpdated',
            header: 'Last Updated',
            Cell: ({ row }) => {
                return row.original.lastUpdated
                    ? `${moment(row.original.lastUpdated).format('DD/MM/YYYY hh:mm:ss')}`
                    : null;
            },
            enableGlobalFilter: false, // Not searchable
            size: 100,
        },
    ];
    return columns;
};
const columnsPVD: Array<MRT_ColumnDef<Data>> = [
    {
        accessorKey: 'pid',
        header: 'PID',
        size: 110,
    },
    {
        accessorKey: 'did',
        header: 'DID',
        size: 110,
    },
    {
        accessorKey: 'rid',
        header: 'RID',
        size: 110,
    },
    {
        accessorKey: 'eid',
        header: 'EID',
        size: 110,
    },
];

export { columnsPVD, getColumns };
