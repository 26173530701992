import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { getBrandIds, getBrands, getBrandUsers, getOids, getUsers, getVendors } from '../../Api/portal-api';
import { allBrandIds } from '../../Helpers/constants';
import { Brand, useBrandedPortal } from '../../Helpers/hooks/useBrandedPortal';
import { Id, Vendor } from '../../Helpers/types';
import { User } from '../../Services/auth';
import EditUser from './EditUser';
import UserList from './UserList';

interface AdminProps {
    url: string;
}

const Admin = (props: AdminProps) => {
    const brandedPortal = useBrandedPortal();
    const isBrandedPortal = brandedPortal.isBrandedPortal;

    const [data, setData] = useState<User[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [selectedItem, setSelectedItem] = useState<User | undefined>();
    const [itemWasUpdated, setItemWasUpdated] = useState(false);
    const [vendors, setVendors] = useState<Vendor[]>([]);
    const [brands, setBrands] = useState<Brand[]>([]);
    const [brandIds, setBrandIds] = useState<Id[]>([]);
    const [oIds, setOIds] = useState<Id[]>([]);

    const loadUsers = () => {
        isBrandedPortal
            ? getBrandUsers(
                  brandedPortal.brand,
                  (data) => {
                      setData(data as unknown as User[]);
                      setIsLoading(false);
                      setIsError(false);
                      setSelectedItem(undefined);
                      setItemWasUpdated(false);
                  },
                  () => {
                      setData([]);
                      setIsLoading(false);
                      setIsError(true);
                      setSelectedItem(undefined);
                      setItemWasUpdated(false);
                  },
              )
            : getUsers(
                  (data) => {
                      setData(data as unknown as User[]);
                      setIsLoading(false);
                      setIsError(false);
                      setSelectedItem(undefined);
                      setItemWasUpdated(false);
                  },
                  () => {
                      setData([]);
                      setIsLoading(false);
                      setIsError(true);
                      setSelectedItem(undefined);
                      setItemWasUpdated(false);
                  },
              );
    };

    const loadVendors = () => {
        getVendors(
            (data) => {
                setVendors(data as unknown as Vendor[]);
            },
            () => {
                setVendors([]);
            },
        );
    };

    const loadOids = () => {
        getOids(
            (data) => {
                setOIds(data as unknown as Id[]);
            },
            () => {
                setOIds([]);
            },
        );
    };

    const loadBrands = () => {
        getBrands(
            (data) => {
                setBrands(data as unknown as Brand[]);
            },
            () => {
                setBrands([]);
            },
        );
    };

    const loadBrandIds = () => {
        getBrandIds(
            (data) => {
                setBrandIds((data as unknown as Id[]).sort((a: Id, b: Id) => (b.name === allBrandIds.dbName ? 1 : -1)));
            },
            () => {
                setBrandIds([]);
            },
        );
    };
    useEffect(() => {
        loadUsers();
        loadVendors();
        loadBrandIds();
        loadOids();
        if (!isBrandedPortal) {
            loadBrands();
        }
    }, []);

    useEffect(() => {
        if (itemWasUpdated) {
            loadUsers();
        }
    }, [itemWasUpdated]);

    const onEditDoneHandler = () => {
        setItemWasUpdated(true);
    };

    return (
        <>
            <Route path={`${props.url}/`} exact>
                <UserList
                    data={data}
                    isLoading={isLoading}
                    isError={isError}
                    onEditUser={(user) => setSelectedItem(user)}
                ></UserList>
            </Route>
            <Route path={`${props.url}/add-user`} exact>
                <EditUser
                    brands={brands}
                    brandIds={brandIds}
                    vendors={vendors}
                    oids={oIds}
                    onEditDone={onEditDoneHandler}
                ></EditUser>
            </Route>
            <Route path={`${props.url}/edit-user`} exact>
                <EditUser
                    brands={brands}
                    brandIds={brandIds}
                    vendors={vendors}
                    oids={oIds}
                    onEditDone={onEditDoneHandler}
                    user={selectedItem}
                ></EditUser>
            </Route>
        </>
    );
};

export default Admin;
