import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles()((theme: Theme) => ({
    headerLogo: {
        width: `calc(100vw - ${theme.layout.leftDrawerWidth}px)`,
        transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        display: 'flex',
        justifyContent: 'center',
        '& .MuiAvatar-root': {
            width: 400,
            height: 66,
            marginTop: theme.spacing(6),
            [theme.breakpoints.down('md')]: {
                marginTop: 60,
            },
            '& img': {
                width: 'auto',
                maxHeight: 66,
                [theme.breakpoints.down('sm')]: {
                    maxHeight: 55,
                    paddingLeft: '2%',
                },
            },
        },
    },
    logoMenuExpanded: {
        width: `calc(100vw -  ${theme.layout.menuWidth}px)`,
        transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    logoIntegration: {
        width: '100%',
    },
}));

export default useStyles;
