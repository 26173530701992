import { Autocomplete, Box, Checkbox, Divider, ListItem, Popover, Popper, TextField, Typography } from '@mui/material';
import { Button, Search } from '@taycor/ui-library';
import React, { useEffect, useRef, useState } from 'react';
import { Id } from '../../Helpers/types';
import useStyles from './pvdsAutocomplete.style.ts';

interface IdsAutocompleteProps {
    idsArray: Id[];
    openInitital: boolean;
    anchorEl: Element | null;
    handleSelectedIds: (selected: Id[]) => void;
    filtering: boolean;
    chipping: boolean;
    selectedCome?: Id[];
    handleClose: () => void;
    userIds?: Id[];
    type?: string;
    brandIds?: string[];
}

const IdsAutocomplete = ({
    idsArray,
    openInitital,
    anchorEl,
    handleSelectedIds,
    filtering,
    chipping,
    selectedCome,
    handleClose,
    userIds,
    type,
    brandIds,
}: IdsAutocompleteProps) => {
    const { classes } = useStyles();

    const [ids, setIds] = useState<Id[]>([]);
    const [selectedIds, setSelectedIds] = useState<Id[]>([]);
    const selectedIdsRef = useRef(selectedIds);
    const [open, setOpen] = useState<boolean>(false);

    const [checkedIds, setCheckhedIds] = useState<boolean>(false);

    useEffect(() => {
        setIds(idsArray);
    }, [idsArray]);

    useEffect(() => {
        if (!filtering && !chipping) {
            handleSelectedIds(selectedIds);
        }
    }, [selectedIds]);

    useEffect(() => {
        setOpen(openInitital);
    }, [openInitital]);
    useEffect(() => {
        if (selectedCome) {
            setSelectedIds(selectedCome);
        }
    }, [selectedCome]);
    useEffect(() => {
        if (userIds) {
            const userPVDIdsArray = userIds.map((s) => s.name);
            const filteredPVDIds = ids.filter((x) => userPVDIdsArray.includes(x.name));
            setSelectedIds(filteredPVDIds);
        } else {
            if (brandIds) {
                const filteredPVDIds = selectedIdsRef.current.filter((p) => brandIds?.includes(p.name));
                setSelectedIds(filteredPVDIds);
            }
        }
    }, [userIds, ids, brandIds]);

    useEffect(() => {
        selectedIdsRef.current = selectedIds;
    }, [selectedIds]);

    const autocompleteId = () => {
        switch (type) {
            case 'bids':
                return 'bidsaccess';
            case 'oids':
                return 'oidsaccess';
        }
    };
    return (
        <>
            <Popover
                onClose={() => handleClose()}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={
                    filtering ? { vertical: 'top', horizontal: 'right' } : { vertical: 'bottom', horizontal: 'left' }
                }
            >
                <Box className={classes.pvdsPopoverBox}>
                    <Autocomplete
                        open={true}
                        multiple
                        value={selectedIds}
                        id={autocompleteId()}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        // onChange={(_: any, newValue: any, reason: AutocompleteChangeReason) => {
                        //     if (reason === 'select-option') {
                        //         setSelectedIds(newValue);
                        //     } else {
                        //         if (reason === 'remove-option') {
                        //             setCheckhedIds(false);
                        //             setSelectedIds(newValue);
                        //         }
                        //     }
                        // }}
                        classes={{
                            paper: classes.pvdsAutocomplete,
                            option: classes.pvdsMenuOption,
                            listbox: classes.pvdsMenu,
                        }}
                        slots={{
                            popper: (props) => <Popper {...props} placement="bottom-start" />,
                        }}
                        disableCloseOnSelect
                        disablePortal={true}
                        renderTags={() => null}
                        noOptionsText="No results."
                        renderOption={(props, option, { selected }) => {
                            const { key: _, ...rest } = props;
                            return (
                                <ListItem style={{ padding: 0 }} key={rest.id} {...rest}>
                                    <Checkbox
                                        color="primary"
                                        onChange={() => {
                                            if (selected) {
                                                const filtered = selectedIds?.filter(
                                                    (item) => item.name !== option.name,
                                                );
                                                setCheckhedIds(false);
                                                setSelectedIds(filtered);
                                            } else {
                                                setSelectedIds([...selectedIds, option]);
                                            }
                                        }}
                                        checked={
                                            chipping
                                                ? selectedIds.some((element) => element.name === option.name)
                                                : selected || checkedIds
                                        }
                                    />
                                    <Typography>{option.name}</Typography>
                                </ListItem>
                            );
                        }}
                        options={ids.sort((a, b) => a.name.localeCompare(b.name))}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                className={classes.pvdsTextField}
                                {...params}
                                placeholder="Search"
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: <Search></Search>,
                                    endAdornment: <></>,
                                    className: classes.pvdsSearch,
                                }}
                            />
                        )}
                    />

                    <Box className={classes.pvdsBox}>
                        <Divider />
                        <Box className={classes.pvdsButtonBox}>
                            <Box className={classes.pvdsSelectClearBox}>
                                <Button
                                    variant="text"
                                    label="Select all"
                                    classes={{ root: classes.pvdsSelectAllButton }}
                                    action={() => {
                                        setSelectedIds(ids);
                                        setCheckhedIds(true);
                                    }}
                                ></Button>
                                {selectedIds.length > 0 && (
                                    <Button
                                        variant="text"
                                        label="Clear selection"
                                        classes={{ root: classes.pvdssClearSelectionButton }}
                                        action={() => {
                                            setSelectedIds([]);
                                            setCheckhedIds(false);
                                        }}
                                    ></Button>
                                )}
                            </Box>
                            <Box className={classes.pvdsAddSaveFilterBox}>
                                {filtering && selectedIds.length > 0 && (
                                    <Button
                                        style="rectangle"
                                        color="secondary"
                                        variant="contained"
                                        label="Add filter"
                                        classes={{ root: classes.pvdAddFilterButton }}
                                        action={() => {
                                            handleSelectedIds(selectedIds);
                                        }}
                                    ></Button>
                                )}
                                {!filtering && chipping && (
                                    <Button
                                        style="rectangle"
                                        color="secondary"
                                        variant="contained"
                                        label="Save"
                                        classes={{ root: classes.pvdAddFilterButton }}
                                        action={() => {
                                            handleSelectedIds(selectedIds);
                                        }}
                                    ></Button>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Popover>
        </>
    );
};

export default IdsAutocomplete;
