import Typography from '@mui/material/Typography';
import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { AuthConsumer } from '../Auth';
import { Role } from '../../Services/auth';

interface RoleRouteProps {
    children?: React.ReactNode;
    role: Role[];
    path: string;
    render: ({ match: { url } }: RouteComponentProps) => JSX.Element;
}
function RoleRoute(props: RoleRouteProps) {
    const { children, role, ...rest } = props;
    return (
        <AuthConsumer>
            {({ isAuthenticated, isInRoles }) => {
                if (!isAuthenticated()) {
                    return <Redirect to="/login" />;
                }
                if (isInRoles(role)) {
                    return <Route {...rest}>{children}</Route>;
                } else {
                    return (
                        <Route {...rest}>
                            <Typography>Unauthorized</Typography>
                        </Route>
                    );
                }
            }}
        </AuthConsumer>
    );
}

export default RoleRoute;
