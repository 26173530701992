import { AllBrandIds, AllOIDs } from './types';

export const allBrandIds: AllBrandIds = {
    name: 'All Brand IDs',
    dbName: 'AllBrandIds',
};

export const allOids: AllOIDs = {
    name: 'All OIDs',
    dbName: 'AllOIDs',
};
