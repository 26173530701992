import { useTheme } from '@mui/material/styles';
import { SortingState } from '@tanstack/table-core';
import React from 'react';
import { getFundedTransactions } from '../../../Api';
import { Data } from '../../../Helpers/deal-pipelines';
import { Vendor } from '../../../Helpers/types';
import withData from '../../Data/withData';
import BaseView from '../common/BaseView';
import { columnsPVD, getColumns } from './config';
interface ViewProps {
    data?: Array<Data>;
    isLoading?: boolean;
    vendors?: Array<Vendor>;
    onReload?: () => void;
    menuExpanded: boolean;
    hiddenColumns?: Record<string, boolean>;
    defaultSorting?: SortingState;
}
function View(props: ViewProps) {
    const theme = useTheme();
    return (
        <BaseView
            columns={getColumns(theme)}
            columnsPVD={columnsPVD}
            hiddenColumns={{
                oid: false,
                bid: false,
                pid: false,
                did: false,
                rid: false,
                eid: false,
                acctAmtPayable: false,
                vendorSalesRep: false,
                lastUpdated: false,
            }}
            defaultSorting={[
                {
                    id: 'lastUpdated',
                    desc: true,
                },
            ]}
            title="Funded Transactions"
            subtitle="These are companies that have successfully completed the journey and have fully funded."
            data={props?.data || []}
            isLoading={props?.isLoading || false}
            vendors={props?.vendors || []}
            onReload={props?.onReload || Function}
            {...props}
        ></BaseView>
    );
}

export default withData(View, getFundedTransactions);
