import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import Close from '@mui/icons-material/Close';
import { Box, Chip, Divider, Popover, TextField } from '@mui/material';
import { Button, Plus } from '@taycor/ui-library';
import React, { useCallback, useEffect, useState } from 'react';
import { getBrandIds, getOids, getParentVendorDealer } from '../../../../Api/portal-api';
import { Id, ParentVendorDealer } from '../../../../Helpers/types';
import IdsAutocomplete from '../../../Common/IdsAutocomplete';
import PvdsAutocomplete from '../../../Common/PvdsAutocomplete';
import useStyles from './leadSourceFilter.style';

interface MenuOptionsPVDProps {
    pvdIds: ParentVendorDealer[];
    type: string;
    handleSelectedPVDIds: (selected: ParentVendorDealer[], type: string) => void;
    selectedPVDIdsFromChips: ParentVendorDealer[];
}

const MenuOptionPVD = ({ pvdIds, type, handleSelectedPVDIds, selectedPVDIdsFromChips }: MenuOptionsPVDProps) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [selectedPvdIds, setSelectedPVDIds] = useState<ParentVendorDealer[]>([]);

    const text = () => {
        switch (type) {
            case 'pids':
                return 'Parent ID (PID)';
            case 'vids':
                return 'Vendor ID (VID)';
            case 'dids':
                return 'Dealer ID (DID)';
            case 'rids':
                return 'Representative ID (RID)';
        }
    };

    useEffect(() => {
        setSelectedPVDIds(selectedPVDIdsFromChips);
    }, [selectedPVDIdsFromChips]);

    const handleSelection = (selected: ParentVendorDealer[]) => {
        setOpen(false);
        setSelectedPVDIds(selected);
        handleSelectedPVDIds(selected, type);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <TextField
                multiline
                value={text()}
                variant="standard"
                className={open ? classes.pvdOpen : classes.pvdNotOpen}
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    setOpen(!open);
                    setAnchorEl(e.currentTarget);
                }}
                slotProps={{
                    input: {
                        disableUnderline: true,
                        endAdornment: (
                            <>
                                {open ? (
                                    <ArrowBackIos className={classes.arrowAdornment}></ArrowBackIos>
                                ) : (
                                    <ArrowForwardIos className={classes.arrowAdornment}></ArrowForwardIos>
                                )}
                            </>
                        ),
                    },
                }}
            ></TextField>
            <PvdsAutocomplete
                pvdIdsArray={pvdIds}
                openInitital={open}
                anchorEl={anchorEl}
                handleSelectedPVDIds={handleSelection}
                filtering={true}
                chipping={false}
                selectedCome={selectedPvdIds}
                handleClose={handleClose}
                type={type}
            />
        </>
    );
};
interface MenuOptionsProps {
    ids: Id[];
    type: string;
    handleSelectedIds: (selected: Id[], type: string) => void;
    selectedIdsFromChips: Id[];
}

const MenuOption = ({ ids, type, handleSelectedIds, selectedIdsFromChips }: MenuOptionsProps) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [selectedIds, setSelectedIds] = useState<Id[]>([]);

    const text = () => {
        switch (type) {
            case 'bids':
                return 'Brand ID (BID)';
            case 'oids':
                return 'OID';
        }
    };

    useEffect(() => {
        setSelectedIds(selectedIdsFromChips);
    }, [selectedIdsFromChips]);

    const handleSelection = (selected: Id[]) => {
        setOpen(false);
        setSelectedIds(selected);
        handleSelectedIds(selected, type);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <TextField
                multiline
                value={text()}
                variant="standard"
                className={open ? classes.pvdOpen : classes.pvdNotOpen}
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    setOpen(!open);
                    setAnchorEl(e.currentTarget);
                }}
                InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                        <>
                            {open ? (
                                <ArrowBackIos className={classes.arrowAdornment}></ArrowBackIos>
                            ) : (
                                <ArrowForwardIos className={classes.arrowAdornment}></ArrowForwardIos>
                            )}
                        </>
                    ),
                }}
            ></TextField>
            <IdsAutocomplete
                idsArray={ids}
                openInitital={open}
                anchorEl={anchorEl}
                handleSelectedIds={handleSelection}
                filtering={true}
                chipping={false}
                selectedCome={selectedIds}
                handleClose={handleClose}
                type={type}
            ></IdsAutocomplete>
        </>
    );
};

interface FilterChipProps {
    selectedPVDIds: ParentVendorDealer[] | Id[];
    type: string;
    handleChipOpen: (currentTarget: Element, type: string) => void;
    handleChipDelete: (type: string) => void;
}

const FilterChip = ({ selectedPVDIds, type, handleChipOpen, handleChipDelete }: FilterChipProps) => {
    const getName = () => {
        switch (type) {
            case 'oids':
                return 'O';
            case 'bids':
                return 'Brand ';
            case 'pids':
                return 'Parent ';
            case 'vids':
                return 'Vendor ';
            case 'dids':
                return 'Dealer ';
            case 'rids':
                return 'Representative ';
        }
    };

    return (
        <Chip
            style={{ backgroundColor: '#EFF1F5', borderRadius: 1, border: 0 }}
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                handleChipOpen(e.currentTarget, type);
            }}
            onDelete={() => {
                handleChipDelete(type);
            }}
            variant="outlined"
            label={`${getName()} IDs: ${selectedPVDIds[0].name}, ${
                selectedPVDIds.length === 1 ? '' : ` +${selectedPVDIds.length - 1}`
            }`}
            deleteIcon={<Close style={{ width: 30, height: 15, color: '#14679F' }} />}
        ></Chip>
    );
};

interface LeadSourceFilterProps {
    handlePVDIds: (values: ParentVendorDealer[], bids: Id[], oIds: Id[]) => void;
}
const LeadSourceFilter = ({ handlePVDIds }: LeadSourceFilterProps) => {
    const { classes } = useStyles();

    const [bids, setBids] = useState<Id[]>([]);
    const [oids, setOids] = useState<Id[]>([]);
    const [pids, setPids] = useState<ParentVendorDealer[]>([]);
    const [vids, setVids] = useState<ParentVendorDealer[]>([]);
    const [dids, setDids] = useState<ParentVendorDealer[]>([]);
    const [rids, setRids] = useState<ParentVendorDealer[]>([]);

    const [selectedBids, setSelectedBids] = useState<Id[]>([]);
    const [selectedOids, setSelectedOids] = useState<Id[]>([]);
    const [selectedPids, setSelectedPids] = useState<ParentVendorDealer[]>([]);
    const [selectedVids, setSelectedVids] = useState<ParentVendorDealer[]>([]);
    const [selectedDids, setSelectedDids] = useState<ParentVendorDealer[]>([]);
    const [selectedRids, setSelectedRids] = useState<ParentVendorDealer[]>([]);

    const [mainOpen, setMainOpen] = useState<boolean>(false);

    const [bidsChipOpen, setBidsChipOpen] = useState<boolean>(false);
    const [oidsChipOpen, setOidsChipOpen] = useState<boolean>(false);
    const [pidsChipOpen, setPidsChipOpen] = useState<boolean>(false);
    const [vidsChipOpen, setVidsChipOpen] = useState<boolean>(false);
    const [didsChipOpen, setDidsChipOpen] = useState<boolean>(false);
    const [ridsChipOpen, setRidsChipOpen] = useState<boolean>(false);

    const [mainAnchor, setMainAnchor] = useState<HTMLButtonElement | null>(null);

    const [pidsAnchor, setPidsAnchor] = useState<Element | null>(null);
    const [vidsAnchor, setVidsAnchor] = useState<Element | null>(null);
    const [didsAnchor, setDidsAnchor] = useState<Element | null>(null);
    const [ridsAnchor, setRidsAnchor] = useState<Element | null>(null);
    const [bidsAnchor, setBidsAnchor] = useState<Element | null>(null);
    const [oidsAnchor, setOidsAnchor] = useState<Element | null>(null);

    useEffect(() => {
        const ids = selectedPids.concat(selectedVids, selectedDids, selectedRids);
        handlePVDIds([...ids], selectedBids, selectedOids);
    }, [selectedDids, selectedPids, selectedRids, selectedVids, selectedBids, selectedOids]);

    useEffect(() => {
        const defaultVendors = JSON.parse(sessionStorage.getItem('vendors') || '[]');
        const defaultBids = JSON.parse(sessionStorage.getItem('bids') || '[]');
        const defaultOids = JSON.parse(sessionStorage.getItem('oids') || '[]');

        if (defaultVendors.length > 0) {
            const selectedPidsSession: ParentVendorDealer[] = [];
            const selectedVidsSession: ParentVendorDealer[] = [];
            const selectedDidsSession: ParentVendorDealer[] = [];
            const selectedRidsSession: ParentVendorDealer[] = [];

            defaultVendors.forEach((element: ParentVendorDealer) => {
                if (element.type === 0) {
                    selectedPidsSession.push(element);
                }
                if (element.type === 1) {
                    selectedVidsSession.push(element);
                }
                if (element.type === 2) {
                    selectedDidsSession.push(element);
                }
                if (element.type === 3) {
                    selectedRidsSession.push(element);
                }
            });
            setSelectedPids(selectedPidsSession);
            setSelectedVids(selectedVidsSession);
            setSelectedDids(selectedDidsSession);
            setSelectedRids(selectedRidsSession);
        }
        if (defaultBids.length > 0) {
            const selectedBidsSession: Id[] = [];

            defaultBids.forEach((element: ParentVendorDealer) => {
                selectedBidsSession.push(element);
            });
            setSelectedBids(selectedBidsSession);
        }
        if (defaultOids.length > 0) {
            const selectedOidsSession: Id[] = [];

            defaultOids.forEach((element: ParentVendorDealer) => {
                selectedOidsSession.push(element);
            });
            setSelectedOids(selectedOidsSession);
        }
    }, []);
    const loadBids = useCallback(async () => {
        getBrandIds(
            (data) => {
                setBids((data as unknown as Id[]).filter((b: Id) => b.name != null));
            },
            () => {},
        );
    }, []);

    const loadOids = useCallback(async () => {
        getOids(
            (data) => {
                setOids((data as unknown as Id[]).filter((b: Id) => b.name != null));
            },
            () => {},
        );
    }, []);
    const loadPids = useCallback(async () => {
        getParentVendorDealer(
            (data) => {
                const pidsArray: ParentVendorDealer[] = [];
                const vidsArray: ParentVendorDealer[] = [];
                const didsArray: ParentVendorDealer[] = [];
                const ridsArray: ParentVendorDealer[] = [];

                (data as unknown as ParentVendorDealer[]).forEach((element: ParentVendorDealer) => {
                    if (element.type === 0) pidsArray.push(element);
                    if (element.type === 1) vidsArray.push(element);
                    if (element.type === 2) didsArray.push(element);
                    if (element.type === 3) ridsArray.push(element);
                });

                setPids(pidsArray);
                setVids(vidsArray);
                setDids(didsArray);
                setRids(ridsArray);
            },
            () => {},
        );
    }, []);
    useEffect(() => {
        loadPids();
        loadBids();
        loadOids();
    }, [loadPids, loadBids, loadOids]);

    const handleSelectedBids = (selected: Id[]) => {
        setMainOpen(false);
        setSelectedBids(selected);
        setBidsChipOpen(false);
    };
    const handleSelectedOids = (selected: Id[]) => {
        setMainOpen(false);
        setSelectedOids(selected);
        setOidsChipOpen(false);
    };
    const handleSelectedPids = (selected: ParentVendorDealer[]) => {
        setMainOpen(false);
        setSelectedPids(selected);
        setPidsChipOpen(false);
    };
    const handleSelectedVids = (selected: ParentVendorDealer[]) => {
        setMainOpen(false);
        setSelectedVids(selected);
        setVidsChipOpen(false);
    };
    const handleSelectedDids = (selected: ParentVendorDealer[]) => {
        setMainOpen(false);
        setSelectedDids(selected);
        setDidsChipOpen(false);
    };
    const handleSelectedRids = (selected: ParentVendorDealer[]) => {
        setMainOpen(false);
        setSelectedRids(selected);
        setRidsChipOpen(false);
    };

    const handleBidsChipClose = () => {
        setBidsChipOpen(false);
    };
    const handleOidsChipClose = () => {
        setOidsChipOpen(false);
    };
    const handlePidsChipClose = () => {
        setPidsChipOpen(false);
    };
    const handleVidsChipClose = () => {
        setVidsChipOpen(false);
    };
    const handleDidsChipClose = () => {
        setDidsChipOpen(false);
    };
    const handleRidsChipClose = () => {
        setRidsChipOpen(false);
    };
    const handleSelectedPVDIds = (selected: ParentVendorDealer[], type: string) => {
        switch (type) {
            case 'pids':
                setSelectedPids(selected);
                setMainOpen(false);
                setPidsChipOpen(false);
                break;
            case 'vids':
                setSelectedVids(selected);
                setMainOpen(false);
                setVidsChipOpen(false);
                break;
            case 'dids':
                setSelectedDids(selected);
                setMainOpen(false);
                setDidsChipOpen(false);
                break;
            case 'rids':
                setSelectedRids(selected);
                setMainOpen(false);
                setRidsChipOpen(false);
                break;
        }
    };

    const handleSelectedIds = (selected: Id[], type: string) => {
        switch (type) {
            case 'bids':
                setSelectedBids(selected);
                setMainOpen(false);
                setBidsChipOpen(false);
                break;
            case 'oids':
                setSelectedOids(selected);
                setMainOpen(false);
                setOidsChipOpen(false);
                break;
        }
    };

    const handleChipOpen = (e: Element, type: string) => {
        switch (type) {
            case 'bids':
                setBidsAnchor(e);
                setBidsChipOpen(true);
                break;
            case 'oids':
                setOidsAnchor(e);
                setOidsChipOpen(true);
                break;
            case 'pids':
                setPidsAnchor(e);
                setPidsChipOpen(true);
                break;
            case 'vids':
                setVidsAnchor(e);
                setVidsChipOpen(true);
                break;
            case 'dids':
                setDidsAnchor(e);
                setDidsChipOpen(true);
                break;
            case 'rids':
                setRidsAnchor(e);
                setRidsChipOpen(true);
                break;
        }
    };

    const handleChipDelete = (type: string) => {
        switch (type) {
            case 'bids':
                setSelectedBids([]);
                window.location.reload();
                break;
            case 'oids':
                setSelectedOids([]);
                window.location.reload();
                break;
            case 'pids':
                setSelectedPids([]);
                window.location.reload();
                break;
            case 'vids':
                setSelectedVids([]);
                window.location.reload();

                break;
            case 'dids':
                setSelectedDids([]);
                window.location.reload();

                break;
            case 'rids':
                setSelectedRids([]);
                window.location.reload();

                break;
        }
    };

    return (
        <>
            <Box className={classes.pvdChipBox}>
                {selectedBids.length > 0 && (
                    <FilterChip
                        selectedPVDIds={selectedBids}
                        type={'bids'}
                        handleChipOpen={handleChipOpen}
                        handleChipDelete={handleChipDelete}
                    ></FilterChip>
                )}
                {selectedOids.length > 0 && (
                    <FilterChip
                        selectedPVDIds={selectedOids}
                        type={'oids'}
                        handleChipOpen={handleChipOpen}
                        handleChipDelete={handleChipDelete}
                    ></FilterChip>
                )}
                {selectedPids.length > 0 && (
                    <FilterChip
                        selectedPVDIds={selectedPids}
                        type={'pids'}
                        handleChipOpen={handleChipOpen}
                        handleChipDelete={handleChipDelete}
                    ></FilterChip>
                )}
                {selectedVids.length > 0 && (
                    <FilterChip
                        selectedPVDIds={selectedVids}
                        type={'vids'}
                        handleChipOpen={handleChipOpen}
                        handleChipDelete={handleChipDelete}
                    ></FilterChip>
                )}
                {selectedDids.length > 0 && (
                    <FilterChip
                        selectedPVDIds={selectedDids}
                        type={'dids'}
                        handleChipOpen={handleChipOpen}
                        handleChipDelete={handleChipDelete}
                    ></FilterChip>
                )}
                {selectedRids.length > 0 && (
                    <FilterChip
                        selectedPVDIds={selectedRids}
                        type={'rids'}
                        handleChipOpen={handleChipOpen}
                        handleChipDelete={handleChipDelete}
                    ></FilterChip>
                )}
            </Box>
            <IdsAutocomplete
                idsArray={bids}
                openInitital={bidsChipOpen}
                anchorEl={bidsAnchor}
                handleSelectedIds={handleSelectedBids}
                filtering={false}
                chipping={true}
                selectedCome={selectedBids}
                handleClose={handleBidsChipClose}
            ></IdsAutocomplete>
            <IdsAutocomplete
                idsArray={oids}
                openInitital={oidsChipOpen}
                anchorEl={oidsAnchor}
                handleSelectedIds={handleSelectedOids}
                filtering={false}
                chipping={true}
                selectedCome={selectedOids}
                handleClose={handleOidsChipClose}
            ></IdsAutocomplete>
            <PvdsAutocomplete
                pvdIdsArray={pids}
                openInitital={pidsChipOpen}
                anchorEl={pidsAnchor}
                handleSelectedPVDIds={handleSelectedPids}
                filtering={false}
                chipping={true}
                selectedCome={selectedPids}
                handleClose={handlePidsChipClose}
            ></PvdsAutocomplete>
            <PvdsAutocomplete
                pvdIdsArray={vids}
                openInitital={vidsChipOpen}
                anchorEl={vidsAnchor}
                handleSelectedPVDIds={handleSelectedVids}
                filtering={false}
                chipping={true}
                selectedCome={selectedVids}
                handleClose={handleVidsChipClose}
            ></PvdsAutocomplete>
            <PvdsAutocomplete
                pvdIdsArray={dids}
                openInitital={didsChipOpen}
                anchorEl={didsAnchor}
                handleSelectedPVDIds={handleSelectedDids}
                filtering={false}
                chipping={true}
                selectedCome={selectedDids}
                handleClose={handleDidsChipClose}
            ></PvdsAutocomplete>
            <PvdsAutocomplete
                pvdIdsArray={rids}
                openInitital={ridsChipOpen}
                anchorEl={ridsAnchor}
                handleSelectedPVDIds={handleSelectedRids}
                filtering={false}
                chipping={true}
                selectedCome={selectedRids}
                handleClose={handleRidsChipClose}
            ></PvdsAutocomplete>
            <Box className={classes.pvdActionsBox}>
                <Button
                    icon={<Plus />}
                    classes={{ root: classes.pvdActionsButtons }}
                    variant="text"
                    label="Add filter"
                    action={(e: React.MouseEvent<HTMLButtonElement>) => {
                        setMainOpen(true);
                        setMainAnchor(e.target as HTMLButtonElement);
                    }}
                ></Button>
                {(selectedBids.length > 0 ||
                    selectedOids.length > 0 ||
                    selectedPids.length > 0 ||
                    selectedDids.length > 0 ||
                    selectedRids.length > 0 ||
                    selectedVids.length > 0) && (
                    <>
                        <Divider orientation="vertical" />
                        <Button
                            classes={{ root: classes.pvdActionsButtons }}
                            variant="text"
                            label="Clear"
                            action={() => {
                                setSelectedBids([]);
                                setSelectedOids([]);
                                setSelectedPids([]);
                                setSelectedDids([]);
                                setSelectedVids([]);
                                setSelectedRids([]);
                            }}
                        ></Button>
                    </>
                )}
            </Box>
            <Popover
                onClose={() => setMainOpen(false)}
                classes={{ paper: classes.pvdMainPopover }}
                open={mainOpen}
                anchorEl={mainAnchor}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <MenuOption
                    ids={oids}
                    type={'oids'}
                    handleSelectedIds={handleSelectedIds}
                    selectedIdsFromChips={selectedOids}
                ></MenuOption>
                <MenuOption
                    ids={bids}
                    type={'bids'}
                    handleSelectedIds={handleSelectedIds}
                    selectedIdsFromChips={selectedBids}
                ></MenuOption>
                <MenuOptionPVD
                    pvdIds={pids}
                    type={'pids'}
                    handleSelectedPVDIds={handleSelectedPVDIds}
                    selectedPVDIdsFromChips={selectedPids}
                ></MenuOptionPVD>
                <MenuOptionPVD
                    pvdIds={vids}
                    type={'vids'}
                    handleSelectedPVDIds={handleSelectedPVDIds}
                    selectedPVDIdsFromChips={selectedVids}
                ></MenuOptionPVD>
                <MenuOptionPVD
                    pvdIds={dids}
                    type={'dids'}
                    handleSelectedPVDIds={handleSelectedPVDIds}
                    selectedPVDIdsFromChips={selectedDids}
                ></MenuOptionPVD>
                <MenuOptionPVD
                    pvdIds={rids}
                    type={'rids'}
                    handleSelectedPVDIds={handleSelectedPVDIds}
                    selectedPVDIdsFromChips={selectedRids}
                ></MenuOptionPVD>
            </Popover>
        </>
    );
};

export default LeadSourceFilter;
