import React from 'react';
import { AuthConsumer } from '../Auth';

interface InventoryPermissionProps {
    children: React.ReactNode;
}

const InventoryPermission = (props: InventoryPermissionProps) => {
    return (
        <AuthConsumer>
            {({ getUser }) => {
                const user = getUser();
                return user && user.inventoryEnabled ? props.children : <></>;
            }}
        </AuthConsumer>
    );
};

export default InventoryPermission;
