import { formField } from '../../Theme';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    root: {},
    forgotPasswordText: {
        marginLeft: 13,
    },
    forgotPasswordDetailsText: {
        marginTop: theme.spacing(2),
        marginLeft: 13,
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: theme.spacing(8),
    },
    formField: {
        width: 502,
        ...formField(theme),
    },
    pwdResetInstructions: {
        width: 480,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        marginLeft: 13,
    },
    button: {
        width: 338,
        height: 45,
        marginTop: 62,
        textTransform: 'none',
        color: theme.palette.common.white,
    },
    divider: {
        marginTop: theme.spacing(11),
        width: 502,
        backgroundColor: theme.colors.lightGrey,
    },
    dividerWithAlert: {
        marginTop: theme.spacing(12),
    },
    helpText: {
        color: theme.palette.text.secondary,
        marginTop: 74,
    },
    loginLink: {
        textDecoration: 'none',
        color: theme.palette.text.secondary,
        fontSize: 14,
    },
    visible: {
        display: 'block',
    },
    hidden: {
        display: 'none',
    },
}));

export default useStyles;
