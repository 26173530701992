import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    Autocomplete,
    AutocompleteInputChangeReason,
    AutocompleteProps,
    Checkbox,
    ListItem,
    TextField,
    TextFieldProps,
} from '@mui/material';
import { FieldProps, getIn } from 'formik';
import React from 'react';
import useStyles from './formikAutocomplete.style';

export interface FormikAutocompleteProps<
    V,
    FormValues,
    Multiple extends boolean | undefined = boolean,
    DisableClearable extends boolean | undefined = boolean,
    FreeSolo extends boolean | undefined = boolean,
> extends FieldProps<V, FormValues>,
        AutocompleteProps<V, Multiple, DisableClearable, FreeSolo> {
    textFieldProps: TextFieldProps;
}

const noOp = () => {};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FormikAutocomplete = <V, FormValues>({ textFieldProps, ...props }: FormikAutocompleteProps<V, FormValues>) => {
    const { classes } = useStyles();
    const {
        form: { setTouched, setFieldValue, touched },
    } = props;

    const fieldToTextField = (props: FormikAutocompleteProps<V, FormValues>) => {
        const { onBlur: fieldOnBlur, ...field } = props.field;
        const { isSubmitting, touched, errors } = props.form;

        const fieldError = getIn(errors, field.name);
        const showError = getIn(touched, field.name) && !!fieldError;

        return {
            error: showError,
            helperText: showError ? fieldError : props.textFieldProps.helperText,
            disabled: props.disabled ?? isSubmitting,
            onBlur:
                props.onBlur ??
                function (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) {
                    fieldOnBlur(e ?? field.name);
                },
            ...field,
            ...props,
        };
    };

    const { error, helperText, ...field } = fieldToTextField({ textFieldProps, ...props });
    const { name } = field;
    const onInputChangeDefault = props.onInputChange ?? noOp;
    const onInputChange = !props.freeSolo
        ? props.onInputChange
        : (event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
              setFieldValue(name!, value);
              onInputChangeDefault(event, value, reason);
          };
    const onChange = (event: React.SyntheticEvent, value: V) => {
        field.onChange ? field.onChange(event, value, 'selectOption') : setFieldValue(name!, value);
    };

    return (
        <Autocomplete
            options={props.options}
            value={field.value as V[]}
            onInputChange={onInputChange}
            onBlur={() => setTouched({ ...touched, [name!]: true })}
            multiple
            disableCloseOnSelect
            classes={{
                option: classes.option,
            }}
            renderInput={(props) => <TextField {...props} {...textFieldProps} helperText={helperText} error={error} />}
            renderOption={(_, option, { selected }) => {
                return (
                    <>
                        <ListItem>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                onChange={(e) => onChange(e, option)}
                            />
                            {option as string}
                        </ListItem>
                    </>
                );
            }}
        />
    );
};

export default FormikAutocomplete;
