import { postLogin, postLogout, postRefreshToken } from '../Api';
import { AuthLogin, ParentVendorDealer } from '../Helpers/types';

export interface User {
    id: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    role: string;
    isActive: boolean;
    hasPassword: boolean;
    inventoryEnabled: boolean;
    companyName: string;
    companyLogo: string;
    financeApplicationUrl: string;
    integrationPartner: string | null;
    apiClientId: string | null;
    isPVDUser: boolean;
    lastLogin: string | null;
    leadSource: string[];
    portalApiAccessEnabled: boolean;
    brand: string;
    brandIds: string;
    oIds: string;
    parentVendorDealerIdObject: ParentVendorDealer[];
}
export interface AddUserPayload extends Record<string, unknown> {
    emailAddress: string;
    firstName: string;
    lastName: string;
    companyName: string;
    companyLogo?: string;
    integrationPartner: string;
    financeApplicationUrl: string;
    ParentVendorDealerIds: string;
    leadSource: string[];
    role: string;
    brand: string;
    brandIds: string;
    isActive: boolean;
    inventoryEnabled: boolean;
    portalApiAccessEnabled: boolean;
}

export interface AuthService {
    signin: (u: string, p: string, d: string, r?: string, s?: () => void, e?: () => void) => void;
    signout: (redirectUrl?: string) => void;
    isAuthenticated: () => boolean;
    isInRole: (role: Role) => boolean;
    isInRoles: (roles: Role[]) => boolean;
    getUser: () => User | null;
    getAccessToken: () => string | null;
    setAutomaticTokenRefresh: () => void;
}

export type Role = 'Admin' | 'User' | 'Brand Admin';

const automaticTokenRefreshEnabled: boolean = true;
const defaultTokenLifetime: number = 1800 * 1000; // 30 minutes (in milliseconds)
const refreshTokenOffset: number = 60 * 1000; // 1 minute (in milliseconds)
let refreshTokenHandlerId: NodeJS.Timeout | null;

const signin = (
    username: string,
    password: string,
    brandUrl: string,
    redirectUrl?: string,
    success: () => void = () => {},
    error: () => void = () => {},
) => {
    getStorage().setItem('auth-redirect-uri', window.location.pathname);
    postLogin(
        { username, password, brandUrl },
        (data) => {
            storeLastTokenRefreshTime();
            storeAccessToken((data as unknown as AuthLogin).accessToken);
            storeRefreshToken((data as unknown as AuthLogin).refreshToken);
            storeUser((data as unknown as AuthLogin).user);

            const redirectUri = getStorage().getItem('auth-redirect-uri');
            if (redirectUrl) {
                navigateToScreen(redirectUrl);
            } else if (redirectUri && redirectUri.indexOf('/login') === -1) {
                navigateToScreen(redirectUri);
            } else {
                navigateToScreen();
            }
            success();
        },
        () => {
            error();
        },
    );
};

const signout = () => {
    const redirectUrl = getLoginUrl();
    postLogout(
        getRefreshToken(),
        () => {
            endSession();
            navigateToScreen(redirectUrl);
        },
        () => {
            // we might wanna retry signout
            endSession();
            navigateToScreen(redirectUrl);
        },
    );
};

const isAuthenticated = (): boolean => {
    return !!getUser();
};

const isInRole = (role: Role): boolean => {
    const user = getUser();
    return user ? user.role === role : false;
};

const isInRoles = (roles: Role[]): boolean => {
    const user = getUser();
    if (roles.some((r) => r === user?.role)) {
        return true;
    }
    return false;
};

const getUser = (): User | null => {
    const user = getStorage().getItem('auth-current-user');
    return user ? JSON.parse(user) : null;
};

const getAccessToken = (): string | null => {
    return getStorage().getItem('auth-access-token');
};

const setAutomaticTokenRefresh = () => {
    if (!automaticTokenRefreshEnabled) return;
    if (!isAuthenticated()) return;

    const refreshTimeout = getTokenRefreshTimeout();
    if (refreshTimeout < 0) {
        signout();
        return;
    }

    refreshTokenHandlerId = setTimeout(() => refreshAccessToken(), refreshTimeout);
};

const refreshAccessToken = () => {
    postRefreshToken(
        getRefreshToken(),
        (data) => {
            storeLastTokenRefreshTime();
            storeAccessToken((data as unknown as AuthLogin).accessToken);
            storeRefreshToken((data as unknown as AuthLogin).refreshToken);
            setAutomaticTokenRefresh();
        },
        () => {
            // we might also wanna retry to refresh
            signout();
        },
    );
};

const storeUser = (user: User) => {
    if (!user) return;
    getStorage().setItem('auth-current-user', JSON.stringify(user));
};

const storeAccessToken = (accessToken: string) => {
    getStorage().setItem('auth-access-token', accessToken);
};

const storeRefreshToken = (refreshToken: string) => {
    getStorage().setItem('auth-refresh-token', refreshToken);
};

const getRefreshToken = (): string | null => {
    return getStorage().getItem('auth-refresh-token');
};

const storeLastTokenRefreshTime = () => {
    getStorage().setItem('auth-last-token-refresh', '' + new Date().getTime());
};

const getTokenRefreshTimeout = (): number => {
    const lastTokenRefreshTime = parseInt(getStorage().getItem('auth-last-token-refresh') || '', 10);
    // something went wrong, do not refresh
    if (!lastTokenRefreshTime || isNaN(lastTokenRefreshTime)) return -1;

    const msFromLastRefresh = new Date().getTime() - lastTokenRefreshTime;
    const refreshTimeout = defaultTokenLifetime - refreshTokenOffset;

    // expired, do not refresh
    if (msFromLastRefresh >= defaultTokenLifetime) return -1;
    // close to expiration, refresh now
    if (msFromLastRefresh >= refreshTimeout) return 0;
    // schedule a refresh
    return refreshTimeout - msFromLastRefresh;
};

const endSession = () => {
    if (refreshTokenHandlerId) {
        clearInterval(refreshTokenHandlerId);
        refreshTokenHandlerId = null;
    }
    clearStorage();
    sessionStorage.removeItem('notificationsToggle');
};

const navigateToScreen = (url: string | null | undefined = '/') => {
    window.location.replace(url || '/');
};

const clearStorage = () => {
    getStorage().removeItem('auth-current-user');
    getStorage().removeItem('auth-access-token');
    getStorage().removeItem('auth-refresh-token');
    getStorage().removeItem('auth-redirect-uri');
    getStorage().removeItem('auth-last-token-refresh');
};

const getStorage = () => {
    return window.sessionStorage;
};

const getLoginUrl = () => {
    if (window.location.href.indexOf('integration') !== -1) {
        return '/integration/login';
    }
    return 'login';
};

export default {
    signin,
    signout,
    isAuthenticated,
    isInRole,
    isInRoles,
    getUser,
    getAccessToken,
    setAutomaticTokenRefresh,
};
