import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { Autocomplete, Chip, Grid, Popper, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { getDidsAndRidsWithBrand, getParentVendorDealerWithBrand, getRidsWithBrand } from '../../../../Api/portal-api';
import { ParentVendorDealer } from '../../../../Helpers/types';
import PvdsAutocomplete from '../../../Common/PvdsAutocomplete';
import useStyles from './leadSourceSelector.style';

interface BaseAutocompleteProps {
    pvdIds: ParentVendorDealer[];
    type: string;
    handleSelectedPVDIds: (selected: ParentVendorDealer[], type: string) => void;
    userPVDIds?: ParentVendorDealer[];
    disabled?: boolean;
    brandIds?: string[];
}

const BaseAutocomplete = ({
    pvdIds,
    type,
    handleSelectedPVDIds,
    userPVDIds,
    disabled = false,
    brandIds,
}: BaseAutocompleteProps) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [selectedPVDIds, setSelectedPVDIds] = useState<ParentVendorDealer[]>([]);

    const handleSelection = (selected: ParentVendorDealer[]) => {
        setSelectedPVDIds(selected);
        handleSelectedPVDIds(selected, type);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Autocomplete
                classes={{ tag: classes.limitTag }}
                className={classes.pvdsAutocomplete}
                id={`${type}access`}
                multiple
                freeSolo
                options={[]}
                limitTags={3}
                disabled={disabled}
                value={selectedPVDIds.map((element) => element.name)}
                renderTags={(value: string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                        <Chip
                            style={{
                                borderRadius: 1,
                                border: 0,
                            }}
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                            deleteIcon={<></>}
                        ></Chip>
                    ))
                }
                slots={{
                    popper: (props) => <Popper {...props} placement="bottom-start" />,
                }}
                disableClearable
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={selectedPVDIds.length ? '' : 'Select option(s)'}
                        variant="outlined"
                        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                            if (!disabled) {
                                setAnchorEl(e.currentTarget);
                                setOpen(true);
                            }
                        }}
                        slotProps={{
                            input: {
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {open ? (
                                            <KeyboardArrowUp></KeyboardArrowUp>
                                        ) : (
                                            <KeyboardArrowDown></KeyboardArrowDown>
                                        )}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            },
                        }}
                    />
                )}
            ></Autocomplete>
            <PvdsAutocomplete
                pvdIdsArray={pvdIds}
                openInitital={open}
                anchorEl={anchorEl}
                handleSelectedPVDIds={handleSelection}
                filtering={false}
                chipping={false}
                selectedCome={undefined}
                handleClose={handleClose}
                userPVDIds={userPVDIds}
                brandIds={brandIds}
            ></PvdsAutocomplete>
        </>
    );
};

interface LeadSourceSelectorProps {
    handlePVDIds: (selected: string[]) => void;
    brandIds: string[];
    userPVDIds?: ParentVendorDealer[];
}

const LeadSourceSelector = ({ handlePVDIds, brandIds, userPVDIds }: LeadSourceSelectorProps) => {
    const { classes } = useStyles();

    const [pids, setPids] = useState<ParentVendorDealer[]>([]);
    const [vids, setVids] = useState<ParentVendorDealer[]>([]);
    const [dids, setDids] = useState<ParentVendorDealer[]>([]);
    const [rids, setRids] = useState<ParentVendorDealer[]>([]);
    const [selectedPids, setSelectedPids] = useState<ParentVendorDealer[]>([]);
    const [selectedVids, setSelectedVids] = useState<ParentVendorDealer[]>([]);
    const [selectedDids, setSelectedDids] = useState<ParentVendorDealer[]>([]);
    const [selectedRids, setSelectedRids] = useState<ParentVendorDealer[]>([]);

    const [disabledDidsAndRids, setDisabledDidsAndRids] = useState<boolean>(false);

    useEffect(() => {
        const ids = selectedPids.concat(selectedVids, selectedDids, selectedRids).map((p) => p.id);
        handlePVDIds([...ids]);
    }, [selectedDids, selectedPids, selectedRids, selectedVids]);

    useEffect(() => {
        loadDidsAndRids();
    }, [selectedVids, selectedPids]);
    useEffect(() => {
        loadRids();
    }, [selectedDids]);

    const loadPidsAndVids = useCallback(async () => {
        if (brandIds.length !== 0 && brandIds) {
            getParentVendorDealerWithBrand(
                brandIds,
                (data) => {
                    const pidsArray: ParentVendorDealer[] = [];
                    const vidsArray: ParentVendorDealer[] = [];

                    (data as ParentVendorDealer[]).forEach((element: ParentVendorDealer) => {
                        if (element.type === 0) pidsArray.push(element);
                        if (element.type === 1) vidsArray.push(element);
                    });
                    setPids(pidsArray);
                    setVids(vidsArray);
                },
                () => {},
            );
        }
    }, [brandIds]);
    useEffect(() => {
        loadPidsAndVids();
    }, [loadPidsAndVids]);

    const loadDidsAndRids = useCallback(async () => {
        const pidsAndVids = selectedPids.concat(selectedVids).map((p) => p.id);
        if (!pidsAndVids.length) {
            setDids([]);
            setRids([]);
            setDisabledDidsAndRids(true);
        } else {
            getDidsAndRidsWithBrand(
                brandIds,
                pidsAndVids,
                (data) => {
                    const didsArray: ParentVendorDealer[] = [];
                    const ridsArray: ParentVendorDealer[] = [];

                    (data as ParentVendorDealer[]).forEach((element: ParentVendorDealer) => {
                        if (element.type === 2) didsArray.push(element);
                        if (element.type === 3) ridsArray.push(element);
                    });

                    setDisabledDidsAndRids(false);
                    setDids(didsArray);
                    setRids(ridsArray);
                },
                () => {},
            );
        }
    }, [brandIds, selectedPids, selectedVids]);

    const loadRids = useCallback(async () => {
        const dids = selectedDids.map((p) => p.id);
        if (dids.length) {
            getRidsWithBrand(
                brandIds,
                dids,
                (data) => {
                    const ridsArray: ParentVendorDealer[] = rids;
                    const result = data as ParentVendorDealer[];
                    result.forEach((element) => {
                        ridsArray.push(element as ParentVendorDealer);
                    });
                    setRids(ridsArray);
                },
                () => {},
            );
        }
    }, [brandIds, selectedDids, rids]);

    const handleSelectedPVDIds = (selected: ParentVendorDealer[], type: string) => {
        switch (type) {
            case 'pids':
                setSelectedPids(selected);
                break;
            case 'vids':
                setSelectedVids(selected);
                break;
            case 'dids':
                setSelectedDids(selected);
                break;
            case 'rids':
                setSelectedRids(selected);

                break;
        }
    };

    return (
        <>
            <Grid className={classes.pvdsGrid} item>
                <Typography>Choose PIDs acces</Typography>
                <BaseAutocomplete
                    pvdIds={pids}
                    type={'pids'}
                    handleSelectedPVDIds={handleSelectedPVDIds}
                    userPVDIds={userPVDIds}
                    brandIds={brandIds}
                ></BaseAutocomplete>
            </Grid>
            <Grid className={classes.pvdsGrid} item>
                {' '}
                <Typography>Choose VIDs acces</Typography>
                <BaseAutocomplete
                    pvdIds={vids}
                    type={'vids'}
                    handleSelectedPVDIds={handleSelectedPVDIds}
                    userPVDIds={userPVDIds}
                    brandIds={brandIds}
                ></BaseAutocomplete>
            </Grid>
            <Grid className={classes.pvdsGrid} item>
                {' '}
                <Typography>Choose DIDs acces</Typography>
                <BaseAutocomplete
                    pvdIds={dids}
                    type={'dids'}
                    handleSelectedPVDIds={handleSelectedPVDIds}
                    userPVDIds={userPVDIds}
                    disabled={disabledDidsAndRids}
                    brandIds={brandIds}
                ></BaseAutocomplete>
            </Grid>
            <Grid className={classes.pvdsGrid} item>
                {' '}
                <Typography>Choose RIDs acces</Typography>
                <BaseAutocomplete
                    pvdIds={rids}
                    type={'rids'}
                    handleSelectedPVDIds={handleSelectedPVDIds}
                    userPVDIds={userPVDIds}
                    disabled={disabledDidsAndRids}
                    brandIds={brandIds}
                ></BaseAutocomplete>
            </Grid>
        </>
    );
};

export default LeadSourceSelector;
