import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        marginTop: 38,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.colors.lightGrey,
        borderRadius: 6,
        width: '100%',
    },
    questions: {
        marginTop: theme.spacing(3),
    },
    name: {
        marginTop: theme.spacing(3),
        fontWeight: 300,
    },
    title: {
        fontWeight: 'bold',
    },
    contact: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        '& .MuiListItemIcon-root': {
            minWidth: 32,
        },
    },
}));

export default useStyles;
