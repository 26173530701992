import { Container, Grid } from '@mui/material';
import React from 'react';
import useStyles from './layoutNonMemberIntegration.style';

interface LayoutNonMemberIntegrationProps {
    children: React.ReactNode;
}
const LayoutNonMemberIntegration = (props: LayoutNonMemberIntegrationProps) => {
    const { classes } = useStyles();

    return (
        <Container maxWidth={false} disableGutters={true} className={classes.root}>
            <Grid container direction="column">
                <Grid item className={classes.rightHalf}>
                    {props.children}
                </Grid>
            </Grid>
        </Container>
    );
};

export default LayoutNonMemberIntegration;
