import React from 'react';
import * as signalR from '@microsoft/signalr';
import config from '../../config';
import { Snackbar, List, ListItem, Alert } from '@mui/material';
import logger from '../../Helpers/logger';

interface RecordUpdatedMessage {
    notificationId: string;
    recordId: string;
    vendorName: string;
    companyId: number;
    accountId: number;
    companyName: string;
    loanAmount: number;
    changes: Record<string, unknown>;
}

interface NotificationsState {
    recordUpdatedNotifications: Array<RecordUpdatedMessage>;
}

interface NotificationsProps {
    accessToken: string | null;
}

class Notifications extends React.Component<NotificationsProps, NotificationsState> {
    constructor(props: NotificationsProps) {
        super(props);
        this.state = {
            recordUpdatedNotifications: [],
        };
    }

    componentDidMount() {
        if (!this.props.accessToken) return;

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(config.api.url + '/hubs/notifications', {
                accessTokenFactory: () => this.props.accessToken + '',
            })
            .configureLogging(signalR.LogLevel.Warning)
            .build();

        connection.on('PortalDataRecordUpdated', (data) => {
            this.setState({
                recordUpdatedNotifications: [...this.state.recordUpdatedNotifications, data],
            });
            // clear notifications after 10 seconds
            const notificationIdToClear = data.notificationId;
            setTimeout(
                (id) => {
                    this.setState({
                        recordUpdatedNotifications: this.state.recordUpdatedNotifications.filter(
                            (n) => n.notificationId !== id,
                        ),
                    });
                },
                10000,
                notificationIdToClear,
            );
        });

        connection.start().catch((err) => logger.logError(err, '[notifications]'));
    }
    render() {
        const notificationClose = (notificationId: string) => {
            this.setState({
                recordUpdatedNotifications: this.state.recordUpdatedNotifications.filter(
                    (n) => n.notificationId !== notificationId,
                ),
            });
        };
        const getMessage = (notification: RecordUpdatedMessage) => {
            if (!notification) return null;
            if (notification.accountId)
                return `Transaction for company ${notification.companyName} (amount ${Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(notification.loanAmount)}) was updated!`;
            return `Lead for company ${notification.companyName} was updated!`;
        };
        return (
            <Snackbar
                open={this.state.recordUpdatedNotifications.length > 0}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <List>
                    {this.state.recordUpdatedNotifications.map((notification: RecordUpdatedMessage, index: number) => (
                        <ListItem key={index}>
                            <Alert
                                key={index}
                                onClose={() => notificationClose(notification.notificationId)}
                                severity="success"
                                style={{ width: '100%' }}
                            >
                                {getMessage(notification)}
                            </Alert>
                        </ListItem>
                    ))}
                </List>
            </Snackbar>
        );
    }
}

export default Notifications;
