export default {
    api: {
        url: 'https://api.portal.taycor.com/api/v1',
    },
    ga: {
        id: 'UA-2015678-10',
    },
    ai: {
        key: 'c3992e32-4191-4592-95b8-9533eae87c3b',
    },
    uploadApi: {
        url: 'https://docs.taycor.com/api/v1',
    },
    midpointPortal: 'portal.midpointef.com',
    pvd: {
        enabled: true,
    },
};
