import config from '../../config';

export interface Brand {
    name: string;
}

export const useBrandedPortal = () => {
    if (window.location.hostname === config.midpointPortal) {
        return {
            isBrandedPortal: true,
            brand: 'Midpoint',
            brandIds: ['midpoint'],
            logoPath: '/midpoint_logo.png',
            logoVerticalPath: '/2023_Midpoint_Logo_Vertical_RGB_White.png',
            logoSubmarkPath: '/2023_Midpoint_Logo_Submark_RGB_white.png',
            favicon: '/midpointef.ico',
        };
    }
    return {
        isBrandedPortal: false,
        brand: '',
        brandIds: [],
        logoPath: '',
    };
};
