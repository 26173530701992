import { useRef, useEffect } from 'react';

export const useScrollIntoViewRef = (scrollIt: boolean) => {
    const elementRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setTimeout(() => {
            scrollIt && elementRef && elementRef.current && elementRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 500);
    }, [scrollIt]);

    return elementRef;
};
