import axios, { AxiosError, AxiosResponse } from 'axios';
import config from '../config';
import logger from '../Helpers/logger';
import auth from '../Services/auth';

export interface CustomError extends AxiosError {
    error?: string;
}
const getAuthHeader = () => {
    return { Authorization: 'Bearer ' + auth.getAccessToken() };
};

const get = <D, T>(
    url: D,
    success: (data: AxiosResponse<T> | AxiosResponse<T[]> | unknown[]) => void,
    error: (err: AxiosError) => void,
    fakeData?: unknown[],
) => {
    const endpoint = config.api.url + url;
    if (config.api.fakeIt) {
        setTimeout(() => success(fakeData || []), 500);
    } else {
        axios
            .get(endpoint, { headers: getAuthHeader() })
            .then((response) => {
                success(response.data);
            })
            .catch((err) => {
                logger.logError(err);
                error(err);
            });
    }
};

const post = <D, T>(
    url: string,
    data: D,
    success: (data: AxiosResponse<T[]> | AxiosResponse<T> | unknown[] | { succes: boolean }) => void,
    error: (err: AxiosError) => void,
    fakeData?: unknown[],
) => {
    const endpoint = config.api.url + url;
    if (config.api.fakeIt) {
        setTimeout(() => success(fakeData || { succes: true }), 500);
    } else {
        axios
            .post(endpoint, data, { headers: getAuthHeader() })
            .then((response) => success(response.data))
            .catch((err: AxiosError) => {
                logger.logError(err);
                error(err);
            });
    }
};

const postWithFiles = <D extends Record<string, unknown>, T>(
    url: string,
    data: D,
    files: {
        [key: string]: File | null;
    },
    success: <T>(data: AxiosResponse<T> | { succes: boolean }) => void,
    error: (err: AxiosError) => void,
    fakeData?: AxiosResponse<T>, // fakeData should match AxiosResponse<T>,
) => {
    const endpoint = config.api.url + url;
    if (config.api.fakeIt) {
        setTimeout(() => success(fakeData || { succes: true }), 500);
    } else {
        const formData = new FormData();
        Object.keys(data).map((key) => {
            if (Array.isArray(data[key])) {
                return (data[key] as string[] | Blob[]).map((value, index) =>
                    formData.append(`${key}[${index}]`, `${value}`),
                );
            } else {
                return formData.append(key, data[key] === null ? '' : `${data[key]}`);
            }
        });
        Object.keys(files).map((key) => {
            if (files[key] !== null) formData.append(key, files[key] as File);
        });

        axios
            .post(endpoint, formData, {
                headers: Object.assign(getAuthHeader(), {
                    'Content-Type': 'multipart/form-data',
                }),
            })
            .then((response) => success(response.data))
            .catch((err: AxiosError) => {
                logger.logError(err);
                error(err);
            });
    }
};

const patchWithFiles = <D extends Record<string, unknown>, T>(
    url: string,
    data: D,
    files: {
        [key: string]: File | null;
    },
    success: (response: AxiosResponse<T> | { succes: boolean }) => void,
    error: (err: CustomError) => void,
    fakeData?: AxiosResponse<T>, // fakeData should match AxiosResponse<T>
) => {
    const endpoint = config.api.url + url;
    if (config.api.fakeIt) {
        setTimeout(() => success(fakeData || { succes: true }), 500);
    } else {
        const formData = new FormData();
        Object.keys(data).map((key) => {
            if (Array.isArray(data[key])) {
                return (data[key] as string[] | Blob[]).map((value, index) =>
                    formData.append(`${key}[${index}]`, `${value}`),
                );
            } else {
                return formData.append(key, data[key] === null ? '' : `${data[key]}`);
            }
        });

        Object.keys(files).map((key) => {
            if (files[key] !== null) formData.append(key, files[key] as File);
        });

        axios
            .patch(endpoint, formData, {
                headers: Object.assign(getAuthHeader(), {
                    'Content-Type': 'multipart/form-data',
                }),
            })
            .then((response) => success(response.data))
            .catch((err: CustomError) => {
                logger.logError(err);
                error(err);
            });
    }
};

const del = <T>(
    url: string,
    success: (data: AxiosResponse<T> | { succes: boolean }) => void,
    error: (err: AxiosError) => void,
    fakeData?: AxiosResponse<T>,
) => {
    const endpoint = config.api.url + url;
    if (config.api.fakeIt) {
        setTimeout(() => success(fakeData || { succes: true }), 500);
    } else {
        axios
            .delete(endpoint, { headers: getAuthHeader() })
            .then((response) => success(response.data))
            .catch((err) => {
                logger.logError(err);
                error(err);
            });
    }
};

export { del, get, patchWithFiles, post, postWithFiles };
