import { Box, Button, Divider, LinearProgress, Typography, Alert } from '@mui/material';
import { default as classNames } from 'classnames';
import { Field, Form, Formik } from 'formik';
import { FormikMaterialUITextField as TextField } from '../Common/TextField';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthConsumer } from '../Auth';
import useStyles from './login.style';
interface Values {
    username: string;
    password: string;
}

interface LoginProps {
    redirectUrl?: string;
}

const Login = (props: LoginProps) => {
    const { classes } = useStyles();

    const currentBrand = window.location.hostname;

    const [showLoginError, setShowLoginError] = useState<boolean>(false);
    const { redirectUrl } = props;

    return (
        <Box className={classes.root}>
            <Typography variant="h1" className={classes.partnerPortalText}>
                Partner Portal
            </Typography>
            <Typography variant="h3" className={classes.loginText}>
                Login
            </Typography>
            <AuthConsumer>
                {({ signin }) => {
                    return (
                        <Formik
                            initialValues={{
                                username: '',
                                password: '',
                            }}
                            validate={(values) => {
                                const errors: Partial<Values> = {};
                                if (!values.username) {
                                    errors.username = 'Required';
                                }
                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                setShowLoginError(false);
                                if (!values.username || !values.password) {
                                    setShowLoginError(true);
                                    setSubmitting(false);
                                    return;
                                }
                                // send redirectUrl to signin
                                signin(
                                    values.username,
                                    values.password,
                                    currentBrand,
                                    redirectUrl,
                                    () => {
                                        setSubmitting(false);
                                    },
                                    () => {
                                        setSubmitting(false);
                                        setShowLoginError(true);
                                    },
                                );
                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form className={classes.form}>
                                    <Field
                                        component={TextField}
                                        name="username"
                                        type="text"
                                        label="Email"
                                        variant="standard"
                                        size="medium"
                                        margin="normal"
                                        className={classes.formField}
                                    ></Field>
                                    <Field
                                        component={TextField}
                                        type="password"
                                        label="Password"
                                        name="password"
                                        variant="standard"
                                        size="medium"
                                        margin="normal"
                                        className={classes.formField}
                                    />
                                    {isSubmitting && <LinearProgress />}
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        disabled={isSubmitting}
                                        className={classes.loginInButton}
                                    >
                                        Log In
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    );
                }}
            </AuthConsumer>
            <Typography className={classes.forgotYourPassoword} variant="body1">
                {redirectUrl ? (
                    <Link to="/integration/forgot-password">Forgot your password?</Link>
                ) : (
                    <Link to="/forgot-password">Forgot your password?</Link>
                )}
            </Typography>
            <Box
                className={classNames(classes.alert, {
                    [classes.visible]: showLoginError,
                    [classes.hidden]: !showLoginError,
                })}
            >
                <Alert severity="error">
                    <Typography variant="h6">Incorrect email or password</Typography>
                </Alert>
            </Box>
            <Divider variant="fullWidth" className={classes.divider}></Divider>
            <Typography className={classes.helpText} variant="h6">
                If you need any help signing in, please call your relationship manager or call (800) 322-9738.
            </Typography>
        </Box>
    );
};

export default Login;
