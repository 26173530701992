import ExpandMore from '@mui/icons-material/ExpandMore';
import FileCopy from '@mui/icons-material/FileCopy';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Box,
    Button,
    Chip,
    CircularProgress,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import classnames from 'classnames';
import { useState } from 'react';
import { DropzoneAreaBase, FileObject } from 'react-mui-dropzone';
import { uploadFiles } from '../../../../Api';
import { useScrollIntoViewRef } from '../../../../Helpers/hooks/useScrollIntoViewRef';
import logger from '../../../../Helpers/logger';
import { UploadInstance, UploadInstanceDocument } from '../../../../Helpers/types';
import useStyles from './upload.style';

interface FileUpload extends FileObject {
    notes: string | null;
}

interface UploadProps {
    uploadInstance: UploadInstance | null;
    onUploadDone: (uploadId: string, notes: Array<string>) => void;
}

function Upload(props: UploadProps) {
    const { classes } = useStyles();
    const [files, setFiles] = useState<Array<FileUpload>>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<string> | null>(null);
    const { uploadInstance, onUploadDone } = props;

    const filesLimit = (uploadInstance?.documents.length || 0) * 5;

    const submit = () => {
        if (!uploadInstance) return;

        setIsSubmitting(true);
        setErrors(null);
        const data = { notes: files.map((file) => file.notes || '') };
        const fs = files.map((file) => file.file);
        uploadFiles(uploadInstance.id, data, fs)
            .then(() => {
                setIsSubmitting(false);
                onUploadDone(uploadInstance.id, files.map((file) => file.notes || file.file.name) || []);
            })
            .catch((err) => {
                logger.logError(err?.response);
                if (err && !Array.isArray(err)) {
                    setErrors(['Cannot upload files!']);
                } else {
                    setErrors(
                        (err?.response?.data || []).map(
                            (e: { file: string; message: string }) => `File ${e.file} was rejected. ${e.message}`,
                        ),
                    );
                }
                setIsSubmitting(false);
            });
    };

    const renderDocumentLine = (document: UploadInstanceDocument) => (
        <div className={classes.documentLine} key={document.type + document.details}>
            <div className={classes.documentIndicator}></div>
            <Typography variant="h6">
                {document.type} {document.details}
            </Typography>
        </div>
    );

    const handlePreviewIcon = (fileObject: FileUpload) => {
        const isOther = fileObject.notes === '';
        const notesLabel = isOther ? 'Other' : 'File Type:';
        return (
            <>
                <Grid container>
                    <Grid item xs={12}>
                        <Chip icon={<FileCopy></FileCopy>} label={fileObject.file.name} color="primary"></Chip>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" className={classes.fileTypeText}>
                            {notesLabel}
                        </Typography>
                        <Autocomplete
                            freeSolo
                            options={uploadInstance?.documents.map((doc) => doc.type) || []}
                            value={fileObject.notes}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder={isOther ? 'Please Describe' : 'Please Select'}
                                    variant="standard"
                                    size="medium"
                                    margin="dense"
                                    onChange={(e) => {
                                        const newFiles = [...files];
                                        const currentIndex = newFiles.indexOf(fileObject);
                                        newFiles[currentIndex] = Object.assign(fileObject, {
                                            notes: e.target.value,
                                        });
                                        setFiles(newFiles);
                                    }}
                                />
                            )}
                            onChange={(_, value) => {
                                const newFiles = [...files];
                                const currentIndex = newFiles.indexOf(fileObject);
                                newFiles[currentIndex] =
                                    value?.indexOf('Other') === -1
                                        ? Object.assign(fileObject, {
                                              notes: value,
                                          })
                                        : Object.assign(fileObject, { notes: '' });
                                setFiles(newFiles);
                            }}
                            className={classes.dropzonePreviewSelect}
                        />
                    </Grid>
                </Grid>
            </>
        );
    };

    const detailsTextRef = useScrollIntoViewRef(showDetails);

    return (
        <div className={classes.root}>
            {!uploadInstance && <CircularProgress />}
            {uploadInstance && (
                <>
                    <Typography variant="h4" className={classes.title}>
                        Upload Documents
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        Help us expedite the customer’s financing request by uploading common and helpful documents
                        below. If you have any questions on specific requirements please reach out to the assigned
                        Account Executive on the transaction.
                    </Typography>
                    <div className={classes.documentLines}>
                        {uploadInstance?.documents.map((doc) => renderDocumentLine(doc))}
                    </div>
                    <div
                        className={classnames(classes.dropzoneContainer, {
                            [classes.dropzoneDisabled]: isSubmitting,
                        })}
                    >
                        <DropzoneAreaBase
                            fileObjects={files}
                            onAdd={(newFiles) => {
                                setFiles([...files, ...newFiles.map((f) => Object.assign(f, { notes: null }))]);
                            }}
                            onDelete={(deleteFile) => {
                                setFiles(files.filter((f) => f !== deleteFile));
                            }}
                            onAlert={(message, variant) => {
                                if (variant === 'error') {
                                    setErrors([message]);
                                }
                            }}
                            getPreviewIcon={(file) => handlePreviewIcon(file as FileUpload)}
                            classes={{
                                root: classnames(classes.dropzoneRoot),
                            }}
                            previewGridClasses={{
                                container: classes.dropzoneFilePreview,
                                item: classes.dropzoneFilePreviewItem,
                            }}
                            showPreviewsInDropzone={false}
                            showPreviews
                            previewText="Documents Uploaded"
                            showAlerts={[]}
                            dropzoneText="Upload your file"
                            filesLimit={filesLimit}
                            previewGridProps={{ item: { xs: 12 } }}
                            dropzoneParagraphClass={classes.dropzoneText}
                            acceptedFiles={[
                                '.docx',
                                '.doc',
                                '.xls',
                                '.xlsx',
                                '.jpeg',
                                '.jpg',
                                '.png',
                                '.pdf',
                                '.psd',
                                '.txt',
                                '.ppt',
                                '.pptx',
                                '.csv',
                            ]}
                            maxFileSize={5000000} //5mb
                        />
                    </div>
                    {isSubmitting && (
                        <div className={classes.submitting}>
                            <CircularProgress />
                            <>Your documents are being submitted...</>
                        </div>
                    )}
                    <Button
                        variant="contained"
                        type="button"
                        color="primary"
                        className={classes.button}
                        onClick={submit}
                        disabled={isSubmitting || files.length === 0}
                    >
                        Submit
                    </Button>
                    {errors && (
                        <div className={classes.errors}>
                            <ul>{errors?.map((err, idx) => <li key={idx}>{err}</li>)}</ul>
                        </div>
                    )}
                    <Box className={classes.details}>
                        <Accordion
                            expanded={showDetails}
                            onChange={() => {
                                setShowDetails(!showDetails);
                            }}
                            ref={detailsTextRef}
                        >
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography variant="subtitle1">Need Help?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={classes.detailsText}>
                                    <p>
                                        <Typography variant="h6" component="span">
                                            We are looking forward to processing your financing request in as little
                                            time as possible. You can help us expedite the process by uploading the
                                            documents Taycor needs.
                                        </Typography>
                                    </p>
                                    <p>
                                        <Typography variant="h6" component="span" className={classes.detailsBoldText}>
                                            Easy as 1-2-3:
                                        </Typography>
                                    </p>
                                    <ol>
                                        <li>
                                            <Typography variant="h6" component="span">
                                                Locate the list of documents being requested (
                                                <span className={classes.detailsBoldText}>note:</span> the requested
                                                list is above the grey box at the center of this page and have a bullet
                                                (“
                                                <span style={{ fontWeight: 900 }}>&bull;</span>”) in front of each
                                                requested item).
                                            </Typography>
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            <Typography variant="h6" component="span">
                                                When you have a document to upload, click the grey box to start
                                                uploading it. These file types are accepted: .docx, .doc, .xls, .xlsx,
                                                .jpeg, .jpg, .png, .pdf, .psd, .txt, .ppt, .pptx, .csv.
                                            </Typography>
                                            <br />
                                            <br />
                                        </li>
                                        <li>
                                            <Typography variant="h6" component="span">
                                                Once a file is uploaded, you’ll see{' '}
                                                <span className={classes.detailsBoldText}>“File Type:”</span> to the
                                                right of the uploaded document. Click the{' '}
                                                <span className={classes.detailsBoldText}>“File Type:”</span> drop-down
                                                to select the name of the document you just uploaded.{' '}
                                                <span className={classes.detailsBoldText}>Note:</span> If you’ve
                                                uploaded a document in error, you can remove it by clicking the{' '}
                                                <span className={classes.detailsBoldText}>red trash can</span> icon
                                                directly across from it. If you don’t see a document’s description in
                                                the drop-down, you can select{' '}
                                                <span className={classes.detailsBoldText}>“Other”</span> from the list
                                                and type a description of the document.
                                            </Typography>
                                            <br />
                                            <br />
                                        </li>
                                    </ol>
                                    <p>
                                        <Typography variant="h6" component="span">
                                            Once you are finished uploading your documents, click the{' '}
                                            <span className={classes.detailsBoldText}>"Submit”</span> button. That’s it!
                                            If you have any <span className={classes.detailsBoldText}>questions</span>{' '}
                                            you can contact your Taycor contact at the phone number in the upper right
                                            corner of this page.
                                        </Typography>
                                    </p>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </>
            )}
        </div>
    );
}

export default Upload;
