import React from 'react';
import { getSoldInventory } from '../../../Api';
import withData from '../../Data/withData';
import BaseView from '../../DealPipeline/common/BaseView';
import { getColumns, columnsPVD } from './config';
import { useTheme } from '@mui/material/styles';
import { SortingState } from '@tanstack/table-core';
import { Data } from '../../../Helpers/deal-pipelines';
import { Vendor } from '../../../Helpers/types';

interface ViewProps {
    data?: Array<Data>;
    isLoading?: boolean;
    vendors?: Array<Vendor>;
    onReload?: () => void;
    menuExpanded: boolean;
    hiddenColumns?: Record<string, boolean>;
    defaultSorting?: SortingState;
}
function View(props: ViewProps) {
    const theme = useTheme();
    const columns = getColumns(theme);

    return (
        <BaseView
            columns={columns}
            columnsPVD={columnsPVD}
            defaultSorting={[
                {
                    id: 'lastUpdated',
                    desc: true,
                },
            ]}
            title="Sold Inventory"
            subtitle="Taycor works diligently to provide accurate accounting of equipment inventory that has been sold. If there are any discrepancies to what you see below, please reach out to your relationship manager."
            data={props?.data || []}
            isLoading={props?.isLoading || false}
            vendors={props?.vendors || []}
            menuExpanded={props?.menuExpanded}
            hiddenColumns={props?.hiddenColumns || {}}
            onReload={props?.onReload || Function}
        ></BaseView>
    );
}

export default withData(View, getSoldInventory);
