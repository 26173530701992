import React, { useRef, useState } from 'react';
import { Box, LinearProgress, Button, Typography, Grid, TextField, Alert } from '@mui/material';
import classnames from 'classnames';
import { postSetupApiAccess } from '../../Api';
import useStyles from './apiAccess.style';
import logger from '../../Helpers/logger';
import { User } from '../../Services/auth';
import config from '../../config';
import { ApiAccessDetails } from '../../Helpers/types';

interface ApiAccessProps {
    user: User;
}

const ApiAccess = (props: ApiAccessProps) => {
    const { classes } = useStyles();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [apiAccessDetails, setApiAccessDetails] = useState<ApiAccessDetails | null>({
        clientId: props.user.apiClientId,
        clientSecret: null,
    });

    const clientIdRef = useRef<HTMLInputElement>(null);
    const clientSecretRef = useRef<HTMLInputElement>(null);

    const onGenerateNewClick = () => {
        setIsLoading(true);
        setShowError(false);
        postSetupApiAccess(
            props.user.id,
            (data) => {
                setApiAccessDetails({
                    clientId: (data as ApiAccessDetails).clientId,
                    clientSecret: (data as ApiAccessDetails).clientSecret,
                });
                setIsLoading(false);
            },
            (err) => {
                logger.logError(err);
                setShowError(true);
                setIsLoading(false);
            },
        );
    };

    const copyClientId = () => {
        if (clientIdRef.current) {
            clientIdRef.current.select();
            document.execCommand('copy');
        }
    };

    const copyClientSecret = () => {
        if (clientSecretRef.current) {
            const isPwdInput = clientSecretRef.current.type === 'password';
            if (isPwdInput) clientSecretRef.current.type = 'text';
            clientSecretRef.current.select();
            document.execCommand('copy');
            if (isPwdInput) clientSecretRef.current.type = 'password';
        }
    };

    const toggleShowSecret = () => {
        if (clientSecretRef.current) {
            clientSecretRef.current.type = clientSecretRef.current.type === 'text' ? 'password' : 'text';
        }
    };

    const apiDocsUrl = config.api.url.replace('v1', 'docs');

    return (
        <Box className={classes.root}>
            <Box>
                <Typography variant="h1">API Access</Typography>
                <Typography variant="h6">
                    Here you can see & generate credentials for accessing the API. API documentation is available{' '}
                    <a href={apiDocsUrl} target="_blank" rel="noopener noreferrer">
                        here
                    </a>
                    .
                </Typography>
            </Box>
            <Box className={classes.gridContainer}>
                <Typography variant="h4">API credentials</Typography>
                {apiAccessDetails?.clientSecret && (
                    <Typography variant="body1">
                        Please copy this key and save it somewhere safe. For security reasons, we cannot show it to you
                        again.
                    </Typography>
                )}
                <Grid container direction="column" spacing={2} className={classes.grid}>
                    <Grid item>
                        <Grid container spacing={4} alignItems="center">
                            <Grid item>
                                <Typography variant="h5" className={classes.fieldLabel}>
                                    Client ID
                                </Typography>
                            </Grid>
                            <Grid item>
                                <TextField
                                    inputRef={clientIdRef}
                                    className={classes.field}
                                    inputProps={{ readOnly: true }}
                                    value={apiAccessDetails?.clientId}
                                ></TextField>
                            </Grid>
                            <Grid item>
                                <Button className={classes.button} onClick={() => copyClientId()}>
                                    Copy
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={4} alignItems="center">
                            <Grid item>
                                <Typography variant="h5" className={classes.fieldLabel}>
                                    Client Secret
                                </Typography>
                            </Grid>
                            {apiAccessDetails?.clientSecret && (
                                <>
                                    <Grid item>
                                        <TextField
                                            inputRef={clientSecretRef}
                                            className={classes.field}
                                            inputProps={{ readOnly: true, type: 'password' }}
                                            value={apiAccessDetails?.clientSecret}
                                        ></TextField>
                                    </Grid>
                                    <Grid item>
                                        <Button className={classes.button} onClick={() => copyClientSecret()}>
                                            Copy
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button className={classes.button} onClick={() => toggleShowSecret()}>
                                            Show/Hide
                                        </Button>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item>
                        {isLoading && <LinearProgress></LinearProgress>}
                        <Button
                            color="primary"
                            variant="contained"
                            className={classnames(classes.button, classes.primaryButton)}
                            onClick={() => onGenerateNewClick()}
                            disabled={isLoading}
                        >
                            Generate Credentials
                        </Button>
                        <Box
                            className={classnames(classes.alert, {
                                [classes.visible]: showError,
                                [classes.hidden]: !showError,
                            })}
                        >
                            <Alert severity="error">
                                <Typography variant="h6">
                                    You do not have access to the API or your access has been revoked.
                                </Typography>
                            </Alert>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default ApiAccess;
