import { createTheme, Theme } from '@mui/material/styles';

declare module '@mui/material/styles/createTheme' {
    interface Theme {
        colors: {
            lightBlue: string;
            darkBlue: string;
            lightGrey: string;
            mediumGrey: string;
            darkGrey: string;
            hoverBackground: string;
            menuIconDefault: string;
            tabsBottomBorder: string;
            taycorRepName: string;
            milestonesTime: string;
            scrollbar: string;
        };
        layout: {
            menuWidth: number;
            leftDrawerWidth: number;
            rightDrawerWidth: number;
            rightDrawerNarrowWidth: number;
            rightDrawerTabWidth: number;
            rightDrawerTabNarrowWidth: number;
            pipelineViewMarginLeft: number;
            pipelineViewMarginRight: number;
            scrollbarWidth: number;
            scrollbarNarrowWidth: number;
        };
        pipelines: {
            spacing: {
                left: number;
                right: number;
            };
            statusStyles: {
                default: {
                    backgroundColor: string;
                    color: string;
                };
                [statusName: string]: {
                    backgroundColor: string;
                    color: string;
                };
            };
        };
        rightDrawer: {
            spacing: {
                left: number;
                right: number;
            };
            statusStyles: {
                default: string;
                [statusName: string]: string;
            };
        };
        inventoryTracker: {
            statusStyles: {
                default: {
                    backgroundColor: string;
                    color: string;
                };
                [statusName: string]: {
                    backgroundColor: string;
                    color: string;
                };
            };
        };
    }
}

const theme = (isBrandedPortal: boolean) =>
    createTheme(
        {
            palette: isBrandedPortal
                ? {
                      info: {
                          light: '#1bcace',
                          main: '#14679F',
                      },
                      primary: {
                          main: '#6f4d9e',
                          dark: '#14679F',
                      },
                      secondary: {
                          light: '#fdecea',
                          main: '#fa2f55',
                          dark: '#611a15',
                      },
                      background: {
                          default: '#fff',
                      },
                      text: {
                          primary: '#333333',
                          secondary: '#969899',
                      },
                  }
                : {
                      info: {
                          light: '#1bcace',
                          main: '#14679F',
                      },
                      primary: {
                          main: '#1bcace',
                          dark: '#14679F',
                      },
                      secondary: {
                          light: '#fdecea',
                          main: '#fa2f55',
                          dark: '#611a15',
                      },
                      background: {
                          default: '#fff',
                      },
                      text: {
                          primary: '#333333',
                          secondary: '#969899',
                      },
                  },
            typography: {
                fontFamily: 'Montserrat',
                h1: {
                    fontFamily: 'Chonburi',
                    fontSize: 33,
                    fontWeight: 'normal',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 1.18,
                    letterSpacing: 'normal',
                },
                h2: {
                    fontSize: 24,
                    fontWeight: 300,
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 1.17,
                    letterSpacing: 'normal',
                },
                h3: {
                    fontSize: 20,
                    fontWeight: 500,
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 1.35,
                    letterSpacing: '0.4px',
                },
                h4: {
                    fontSize: 18,
                    fontWeight: 'bold',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 1.39,
                    letterSpacing: '0.36px',
                },
                h5: {
                    fontSize: 16,
                    fontWeight: 600,
                    fontStyle: 'normal',
                    fontStretch: 'normal',
                    letterSpacing: '0.32px',
                },
                h6: {
                    fontSize: 14,
                    fontWeight: 300,
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 1.79,
                    letterSpacing: '0.28px',
                },
                subtitle1: {
                    fontSize: 15,
                    fontWeight: 300,
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 1.67,
                    letterSpacing: '0.3px',
                },
                body1: {
                    fontSize: 13,
                    fontWeight: 300,
                    fontStretch: ' normal',
                    fontStyle: 'normal',
                    lineHeight: '18px',
                    letterSpacing: '0.26px',
                },
                body2: {
                    fontFamily: 'Montserrat',
                    fontSize: 12,
                    fontWeight: 300,
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: '18px',
                    letterSpacing: 'normal',
                },
                overline: {
                    fontSize: 10,
                    fontWeight: 300,
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    letterSpacing: '0.2px',
                    color: '#969899',
                },
            },
            spacing: 4,
            shape: {
                borderRadius: 5,
            },
        },
        {
            colors: {
                lightBlue: isBrandedPortal ? '#2e176c' : '#1bcace',
                darkBlue: isBrandedPortal ? ' #6f4d9e' : '#14679F',
                lightGrey: '#F4F7FC',
                mediumGrey: '#c8c8c8',
                darkGrey: '#969899',
                hoverBackground: '#e7f0f5',
                menuIconDefault: '#999b9c',
                tabsBottomBorder: '#f5f5f5',
                taycorRepName: '#111111',
                milestonesTime: '#034166',
                scrollbar: '#bed0ed',
            },
            layout: {
                menuWidth: 100,
                leftDrawerWidth: 250,
                rightDrawerWidth: 566,
                rightDrawerNarrowWidth: 431,
                rightDrawerTabWidth: 171,
                rightDrawerTabNarrowWidth: 90,
                pipelineViewMarginLeft: 48,
                pipelineViewMarginRight: 54,
                scrollbarWidth: 15,
                scrollbarNarrowWidth: 8,
            },
            pipelines: {
                spacing: {
                    left: 48,
                    right: 54,
                },
                statusStyles: {
                    default: { backgroundColor: '#8d8e8f', color: '#fff' },
                    Stalled: { backgroundColor: '#8d8e8f', color: '#fff' },
                    PassiveLead: { backgroundColor: '#8d8e8f', color: '#fff' },

                    NewLead: { backgroundColor: '#d1e3fb', color: '#000' },
                    ApplicationReceived: { backgroundColor: '#d1e3fb', color: '#000' },
                    PreQualified: { backgroundColor: '#d1e3fb', color: '#000' },
                    InUnderwriting: { backgroundColor: '#d1e3fb', color: '#000' },

                    CustomerActionRequired: { backgroundColor: '#fd97a9', color: '#fff' },
                    CreditPending: { backgroundColor: '#fd97a9', color: '#fff' },
                    NeedsInformation: { backgroundColor: '#fd97a9', color: '#fff' },

                    Approved: { backgroundColor: '#9a9fc7', color: '#fff' },

                    ContractsSent: { backgroundColor: '#8ee4d5', color: '#000' },
                    ContractsReceived: { backgroundColor: '#8ee4d5', color: '#000' },
                    InforFunding: { backgroundColor: '#8ee4d5', color: '#000' },

                    Funded: { backgroundColor: '#01c8cd', color: '#fff' },
                },
            },
            rightDrawer: {
                spacing: {
                    left: 27,
                    right: 23,
                },
                statusStyles: {
                    default: '#969899',
                    NewLead: '#d1e3fb',
                    ApplicationReceived: '#d1e3fb',
                    InUnderwriting: '#d1e3fb',
                    PreQualified: '#d1e3fb',
                    Approved: '#9a9fc7',
                    InforFunding: '#8d8e8f',
                    Funded: '#01c8cd',
                    ContractsSent: '#01c8cd',
                    ContractsReceived: '#01c8cd',
                    CreditPending: '#fd97a9',
                    NeedsInformation: '#fd97a9',
                    Stalled: '#8d8e8f',
                },
            },
            inventoryTracker: {
                statusStyles: {
                    default: { backgroundColor: '#8d8e8f', color: '#fff' },
                    Ordered: { backgroundColor: '#d1e3fb', color: '#000' },
                    DealerInvetory: { backgroundColor: '#9a9fc7', color: '#fff' },
                    SoldtoCustomer: { backgroundColor: '#8ee4d5', color: '#000' },
                    PaidOff: { backgroundColor: '#01c8cd', color: '#fff' },
                },
            },
        },
    );

export const formField = (theme: Theme) => ({
    '& .MuiInputBase-root': {
        fontSize: 15,
        fontWeight: 'normal',
        color: theme.palette.text.secondary,
    },
    '& .MuiFormLabel-root': {
        fontSize: 14,
        fontWeight: 'normal',
        '&.Mui-focused': {
            color: theme.palette.primary.dark,
        },
        '&.Mui-error': {
            color: theme.palette.secondary.main,
        },
    },
    '& .MuiInput-underline': {
        '&:before': {
            borderBottom: `3px solid ${theme.colors.lightGrey}`,
        },
        '&:not(.Mui-error):after': {
            borderBottom: 'none',
        },
        '&:hover': {
            '&:before': {
                borderBottom: `4px solid ${theme.colors.lightGrey}`,
            },
            '&.Mui-error:before': {
                borderBottom: `3px solid ${theme.colors.lightGrey}`,
            },
        },
        '&.Mui-focused': {
            '&:before': {
                borderBottom: `4px solid ${theme.colors.lightGrey}`,
            },
            '&.Mui-error:before': {
                borderBottom: `3px solid ${theme.colors.lightGrey}`,
            },
        },
    },
});

export const scrollbar = (theme: Theme) => ({
    '& .simplebar-vertical': {
        width: theme.layout.scrollbarWidth + 2,
        [theme.breakpoints.down('md')]: {
            width: theme.layout.scrollbarNarrowWidth + 2,
        },
    },
    '& .simplebar-scrollbar::before': {
        backgroundColor: theme.colors.scrollbar,
        left: 1,
        right: 1,
        width: theme.layout.scrollbarWidth,
        [theme.breakpoints.down('md')]: {
            width: theme.layout.scrollbarNarrowWidth,
        },
    },
});

export default theme;
