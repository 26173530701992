import React, { Component } from 'react';
import auth, { AuthService } from '../../Services/auth';

const AuthContext = React.createContext<AuthService>({
    signin: () => {},
    signout: () => {},
    isAuthenticated: () => false,
    isInRole: () => false,
    isInRoles: () => false,
    getUser: () => null,
    getAccessToken: () => null,
    setAutomaticTokenRefresh: () => {},
});

export const AuthConsumer = AuthContext.Consumer;

interface AuthProviderState {
    userSignedIn: boolean;
}
interface AuthProviderProps {
    children: React.ReactNode;
}
export class AuthProvider extends Component<AuthProviderProps, AuthProviderState> {
    private authService;

    constructor(props: AuthProviderProps) {
        super(props);
        this.state = { userSignedIn: false };
        this.authService = auth;
    }

    componentDidMount() {
        this.authService.setAutomaticTokenRefresh();
    }

    render() {
        return <AuthContext.Provider value={this.authService}>{this.props.children}</AuthContext.Provider>;
    }
}
