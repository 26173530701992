import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    option: {
        color: theme.palette.common.black,
    },
}));

export default useStyles;
