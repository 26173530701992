import React from 'react';
import { Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import useStyles from './taycorRep.style';

export interface TaycorRep {
    name: string;
    email: string;
    phone: string;
}
interface TaycorRepProps {
    taycorRep: TaycorRep;
}

const TaycorRepComponent = (props: TaycorRepProps) => {
    const { classes } = useStyles();
    const { taycorRep } = props;

    return (
        <div className={classes.root}>
            <Typography variant="h4" className={classes.questions}>
                Questions?
            </Typography>
            <Typography variant="h4" className={classes.name}>
                {taycorRep.name}
            </Typography>
            <Typography variant="h6" className={classes.title}>
                Account Executive
            </Typography>
            <div className={classes.contact}>
                <List dense>
                    <ListItem>
                        <ListItemIcon>
                            <EmailIcon />
                        </ListItemIcon>
                        <ListItemText primary={taycorRep.email} primaryTypographyProps={{ variant: 'h6' }} />
                    </ListItem>
                </List>
                <List dense>
                    <ListItem>
                        <ListItemIcon>
                            <PhoneIcon />
                        </ListItemIcon>
                        <ListItemText primary={taycorRep.phone} primaryTypographyProps={{ variant: 'h6' }} />
                    </ListItem>
                </List>
            </div>
        </div>
    );
};

export default TaycorRepComponent;
