import { postWithFiles } from './fetch-upload-api';

const uploadFiles = (
    id: string,
    data: {
        notes: string[];
    },
    files: File[],
) => postWithFiles(`/upload/${id}`, data, files);

export { uploadFiles };
