import { formField } from '../../Theme';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    root: {},
    setPasswordText: {
        marginLeft: 13,
    },
    setPasswordDetailsText: {
        marginTop: theme.spacing(2),
        marginLeft: 13,
        width: 480,
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: theme.spacing(8),
    },
    formField: {
        width: 502,
        ...formField(theme),
    },
    button: {
        width: 338,
        height: 45,
        marginTop: 62,
        textTransform: 'none',
        color: theme.palette.common.white,
    },
    buttonLogin: {
        marginTop: 40,
    },
    divider: {
        marginTop: theme.spacing(11),
        width: 502,
        backgroundColor: theme.colors.lightGrey,
    },
    dividerWithAlert: {
        marginTop: theme.spacing(12),
    },
    rulesNoAlert: {
        marginTop: 110,
    },
    rulesWithAlert: {
        marginTop: 74,
    },
    ruleText: {
        color: theme.palette.text.secondary,
    },
    rulePrefix: {
        color: theme.palette.secondary.main,
        fontWeight: 600,
        marginRight: 6,
    },
    alert: {
        marginTop: 51,
        textAlign: 'center',
        width: 502,
    },
    welcomeTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    welcomeIcon: {
        marginLeft: theme.spacing(3),
    },
    welcomeDetailsText: {
        marginTop: theme.spacing(2),
        width: 502,
    },
    pwdReset: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    visible: {
        display: 'block',
    },
    hidden: {
        display: 'none',
    },
}));

export default useStyles;
