import React, { useEffect, useState } from 'react';
import { Drawer, Tabs, Tab, Box, CircularProgress, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { getInventoryItemDetails } from '../../../Api';
import TabPanel from './children/TabPanel';
import EquipmentDetails, { InventoryItemDetails } from './children/EquipmentDetails';
import useStyles from './details.style';

interface InventoryDetailsProps {
    itemId: string;
    onCloseDrawer: () => void;
}

function InventoryDetails(props: InventoryDetailsProps) {
    const { classes } = useStyles();

    const [details, setDetails] = useState<InventoryItemDetails | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        getInventoryItemDetails(
            props.itemId,
            (data) => {
                setDetails(data as InventoryItemDetails);
                setIsLoading(false);
            },
            () => {
                setDetails(null);
                setIsLoading(false);
            },
        );
    }, [props.itemId]);

    return (
        <Drawer
            anchor="right"
            open
            onClose={() => props.onCloseDrawer()}
            variant="persistent"
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <SimpleBar className={classes.simplebar}>
                <Box className={classes.closeIcon}>
                    <Button
                        onClick={() => props.onCloseDrawer()}
                        startIcon={<CloseIcon></CloseIcon>}
                        variant="contained"
                        color="secondary"
                    >
                        <></>
                    </Button>
                </Box>
                <Tabs value={0} indicatorColor="secondary" className={classes.tabs}>
                    <Tab label="Equipment Details" />
                </Tabs>
                <TabPanel value={0} index={0} className={classes.tabPanel}>
                    {isLoading && <CircularProgress></CircularProgress>}
                    {!isLoading && (details ? <EquipmentDetails item={details}></EquipmentDetails> : null)}
                </TabPanel>
            </SimpleBar>
        </Drawer>
    );
}

export default InventoryDetails;
