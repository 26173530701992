import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Tooltip, Typography } from '@mui/material';
import { MaterialReactTable as MaterialTable } from 'material-react-table';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import useStyles from './userList.style';
import { User } from '../../../Services/auth';

interface UserListProps {
    data: Array<User>;
    isLoading: boolean;
    isError: boolean;
    onEditUser: (user: User) => void;
}

const UserList = (props: UserListProps) => {
    const { classes } = useStyles();
    const [pagination, setPagination] = useState({
        pageSize: 20,
        pageIndex: 0,
    });

    return (
        <Box className={classes.root}>
            <MaterialTable
                renderTopToolbarCustomActions={() => {
                    return (
                        <Box flex="1">
                            <Typography variant="h1">Assigned Portal Users</Typography>
                            <Typography variant="h6" className={classes.tableSubtitle}>
                                Here you can manage users associated with your Partner Portal or edit information
                                belonging to an existing user. If you need any assistance you can reach out to us via
                                chat by using the chat icon in the lower right corner of your screen. If you need to add
                                a user, you can do so by click on the “Add User” link at the bottom of the left sidebar.
                                Please note that Company logos are limited to PNG or jpeg file types.
                            </Typography>
                            <Box className={classes.tableIcon}>
                                <Box>
                                    <PersonIcon></PersonIcon>
                                </Box>
                            </Box>
                        </Box>
                    );
                }}
                state={{
                    columnVisibility: {
                        emailAddress: false,
                    },
                    isLoading: props.isLoading,
                    density: 'compact',
                    pagination,
                    showGlobalFilter: true,
                }}
                enableRowActions
                muiTablePaperProps={{
                    elevation: 0, //change the mui box shadow
                    sx: {
                        '& button[aria-label="Show/Hide search"]': {
                            display: 'none',
                        },
                    },
                }}
                muiSearchTextFieldProps={{
                    variant: 'filled',
                }}
                paginationDisplayMode="pages"
                positionPagination="bottom"
                positionGlobalFilter="right"
                enableColumnActions={false}
                enableGlobalFilterModes
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableHiding={false}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: '',
                        size: 50,
                    },
                }}
                muiPaginationProps={{
                    shape: 'rounded',
                    showRowsPerPage: false,
                    showFirstButton: false,
                    showLastButton: false,
                    variant: 'outlined',
                }}
                onPaginationChange={setPagination}
                positionActionsColumn={'last'}
                renderRowActions={({ row }) => (
                    <Link
                        to="/admin/edit-user"
                        onClick={() => {
                            props.onEditUser(row.original);
                        }}
                        className={classes.actionLink}
                    >
                        <Tooltip title="Edit User">
                            <EditIcon></EditIcon>
                        </Tooltip>
                    </Link>
                )}
                columns={[
                    {
                        header: 'Name',
                        Cell: ({ row }) => {
                            return (
                                <>
                                    <Typography variant="h6" className={classes.companyName}>
                                        {row.original.companyName}
                                    </Typography>
                                    <Typography variant="subtitle1">{row.original.emailAddress}</Typography>
                                </>
                            );
                        },
                        accessorKey: 'companyName',
                        size: 300,
                    },
                    {
                        accessorKey: 'emailAddress',
                        header: 'Email Address',
                        enableGlobalFilter: true, // Searchable
                    },
                    {
                        header: 'Type',
                        accessorKey: 'role',
                        Cell: ({ row }) => {
                            return <Typography variant="h6">{row?.original?.role}</Typography>;
                        },
                        size: 90,
                    },
                    {
                        header: 'Last Login',
                        accessorKey: 'lastLogin',
                        Cell: ({ row }) => (
                            <Typography variant="subtitle1">
                                {row.original.hasPassword
                                    ? row.original.lastLogin
                                        ? new Date(row.original.lastLogin + 'Z').toLocaleString()
                                        : 'No logins yet'
                                    : 'Password not set'}
                            </Typography>
                        ),
                        filterFn: (row, id, filterValue) => {
                            const rowData: User = row.getValue(id);
                            const value = rowData.hasPassword
                                ? rowData.lastLogin
                                    ? new Date(rowData.lastLogin + 'Z').toLocaleString()
                                    : 'No logins yet'
                                : 'Password not set';
                            return value.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0;
                        },
                        size: 150,
                    },
                    {
                        accessorKey: 'companyLogo',
                        header: 'Logo',
                        Cell: ({ row }) => {
                            return row.original?.companyLogo ? <CheckIcon /> : <RemoveIcon />;
                        },
                        size: 80,
                    },
                    {
                        accessorKey: 'isActive',
                        header: 'Active',
                        Cell: ({ row }) => {
                            return row.original?.isActive ? <CheckIcon /> : <RemoveIcon />;
                        },
                        size: 80,
                    },
                ]}
                data={props.data}
                layoutMode="grid"
                // options={{
                //   padding: "dense",
                //   tableLayout: "fixed",
                //   paginationType: "stepped",
                //   showFirstLastPageButtons: false,
                //   emptyRowsWhenPaging: false,
                //   searchFieldVariant: "outlined",
                //   searchFieldStyle: searchFieldStyle(theme),
                // }}
                // style={tableStyle}
            ></MaterialTable>
        </Box>
    );
};

export default UserList;
